import React from "react";
import ProfileForm from "../../components/forms/modify/ProfileForm";
import GenericModifyForm from "../../components/forms/modify/GenericModifyForm";

export default function Informations() {
  return (
    <div>
      <ProfileForm />
    </div>
  );
}
