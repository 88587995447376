import React from "react";
import { Link } from "react-router-dom";
import "../../style/sidebarAccount.css";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import DashboardNavbar from "./DashboardNavbar";

const SidebarAccount = () => {
  return (
      <div>
        <DashboardNavbar />
        <div className="account-wrapper">
          <div className="sidebar">
            <div className="menu-account">
              <div className="infos-factures">
              <Link to="/account/informations">Informations{`\u00a0`}personnelles</Link>
              <Link to="/account/mot-de-passe">Modifier mot de passe</Link>
              <Link to="/account/factures">Factures</Link>
                <Link to="/logout" className="logout-responsive logout">Déconnexion</Link>
              </div>
              <div className="deconnexion">
                <Link to="/logout" className="logout logout-desktop">Déconnexion</Link>
              </div>
            </div>
          </div>
        <div className="outlet-account">
          <Outlet />
        </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
  );
};

export default SidebarAccount;
