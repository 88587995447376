import axios from "axios";
import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, Box } from "@mui/material";
import DemandesMoreMenu from "./DemandesMoreMenu";
import { useMyContext } from "../MyContext";

export default function ListeDemandes() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { token } = useMyContext();
    const [salles, setSalles] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // État pour la barre de recherche

    const demandes = axios.create({
        baseURL: `${apiUrl}/demandes`,
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    useEffect(() => {
        demandes.get().then((response) => {
            setData(response.data);
        });
        axios.get(`${apiUrl}/espaces`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSalles(response.data);
        });
    }, [apiUrl, token]);

    const handleChange = async (event, id) => {
        const {checked} = event.target;
    
        // Mise à jour optimiste de l'état
        setData(prevData =>
            prevData.map(item => {
                if (item.id === id) {
                    return { ...item, processed: checked };
                }
                return item;
            })
        );
    
        try {
            const response = await axios.put(`${apiUrl}/demandes/${id}`, { processed: checked }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.status === 200) {
                console.log("Demande mise à jour avec succès");
            }
        } catch (error) {
            // Si la requête échoue, rétablir l'état précédent
            setData(prevData =>
                prevData.map(item => {
                    if (item.id === id) {
                        return { ...item, processed: !checked };
                    }
                    return item;
                })
            );
            alert("Erreur lors de la mise à jour de la demande :" + error);
        }
    };
    

    // Filtrer les données en fonction de la barre de recherche
    const filteredData = data.filter((item) => {
        return (
            item.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.prenom.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (salles.find(salle => salle.id === item.salle)?.nom || "").toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div>
            <Box sx={{ mb: 3 }}>
                <TextField
                    label="Rechercher"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Prénom</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Salle</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Traitée</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Link to={`/salle/${item.id}`}>{item.nom}</Link>
                            </TableCell>
                            <TableCell>{item.prenom}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{
                                salles.length > 0 && (
                                    salles.find(salle => salle.id === item.salle)?.nom || "Salle inconnue"
                                )
                            }</TableCell>
                            <TableCell>{item.message}</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={Boolean(item.processed)}
                                    onChange={(event) => handleChange(event, item.id)}
                                    inputProps={{ 'aria-label': 'traitée' }}
                                />
                            </TableCell>
                            <TableCell>
                                <DemandesMoreMenu demandeId={item.id} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
