import React, {createContext, useContext, useEffect, useState} from "react";
import {format, parseISO} from "date-fns";
import PropTypes from 'prop-types';
import axios from "axios";

const MyContext = createContext();

// Fonction pour utiliser le localStorage comme state
function useLocalStorageState(key, defaultValue) {
    const [state, setState] = useState(() => {
        // Récupérer la valeur stockée dans le localStorage
        const storedValue = localStorage.getItem(key);
        if (storedValue && (storedValue.at(0) === "{" || storedValue.at(0) === "[")) {
            return JSON.parse(storedValue);
        }
        return storedValue ? storedValue : defaultValue;
    });
    // Mettre à jour le localStorage à chaque changement
    useEffect(() => {
        if (typeof state === "object" || Array.isArray(state)) {
            localStorage.setItem(key, JSON.stringify(state));
        } else {
            localStorage.setItem(key, state);
        }
    }, [key, state]);
    return [state, setState];
}

const redirectIfNotAdmin = async () => {
    // Si le token est présent, envoyer la requête pour vérifier le rôle
    if (localStorage.getItem("userToken") && localStorage.getItem("userToken") !== "") {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("userToken");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        try {
            const response = await axios.get(`${apiUrl}/roles`, {
                headers: headers
            });

            // Parcourir les rôles pour vérifier si l'utilisateur est admin
            const isAdmin = response.data.some(role => role.nom === "admin");

            if (!isAdmin) {
                window.location.href = "/";
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des rôles :", error);
        }
    } else {
        // Rediriger vers la page Not Found si aucun token n'est trouvé
        window.location.href = "/not-logged-in";
    }
}


const MyContextProvider = ({children}) => {
    const [token, setToken] = useLocalStorageState("userToken", "");
    const [userRole, setUserRole] = useLocalStorageState("userRole", "client");
    const [userInfos, setUserInfos] = useLocalStorageState("userInfos", []);

    const [selectedServices, setSelectedServices] = useLocalStorageState("selectedServices",[]);
    const [salle, setSalle] = useLocalStorageState("salle", []);
    const [images, setImages] = useLocalStorageState("images", []);
    const [selectedStartMonth, setSelectedStartMonth] = useLocalStorageState("selectedStartMonth", "");
    const [selectedEndMonth, setSelectedEndMonth] = useLocalStorageState("selectedEndMonth", "");
    const [selectedOptionType, setSelectedOptionType] = useLocalStorageState("selectedOptionType", "");
    const [selectedOptionValue, setSelectedOptionValue] = useLocalStorageState("selectedOptionValue", "");
    const [salleId, setSalleId] = useLocalStorageState("salleId", 0);
    const [nb_postes, setNbPostes] = useLocalStorageState("nb_postes", 1);
    const [selectedDates, setSelectedDates] = useLocalStorageState("selectedDates", ["", ""]);
    const [openDaysCount, setOpenDaysCount] = useLocalStorageState("openDaysCount", 0);
    const [selectedMonths, setSelectedMonths] = useLocalStorageState("selectedMonths", 0);
    const [isUserLoggedIn, setIsUserLoggedIn] = useLocalStorageState("isUserLoggedIn", !!token);
    const [selectedStartTime, setSelectedStartTime] = useLocalStorageState("selectedStartTime", "");
    const [selectedEndTime, setSelectedEndTime] = useLocalStorageState("selectedEndTime", "");
    const [selectedHourCount, setSelectedHourCount] = useLocalStorageState("selectedHourCount", 0);
    const [selectedDay, setSelectedDay] = useLocalStorageState("selectedDay", "");
    const [earliestHour, setEarliestHour] = useLocalStorageState("earliestHour", "");
    const [latestHour, setLatestHour] = useLocalStorageState("latestHour", "");
    const [selectedDate, setSelectedDate] = useLocalStorageState("selectedDate", new Date());
    const [selectedTimeRanges, setSelectedTimeRanges] = useLocalStorageState("selectedTimeRanges", []);
    const [startIndex, setStartIndex] = useLocalStorageState("startIndex", -1);
    const [endIndex, setEndIndex] = useLocalStorageState("endIndex", -1);
    const [startYearValue, setStartYearValue] = useLocalStorageState("startYearValue", 0);
    const [endYearValue, setEndYearValue] = useLocalStorageState("endYearValue", 0);
    const [reservationData, setReservationData] = useLocalStorageState("reservationData", "");
    const [type, setType] = useLocalStorageState("type", []);
    const [reinitData, setReinitData] = useLocalStorageState("reinitData", false);
    const [equipements, setEquipements] = useLocalStorageState("equipements", []);
    const [prixSelectionnes, setPrixSelectionnes] = useLocalStorageState("prixSelectionnes", []);
    const [totalPrixEquipements, setTotalPrixEquipements] = useLocalStorageState("totalPrixEquipements", 0);
    const [totalPrixServices, setTotalPrixServices] = useLocalStorageState("totalPrixServices", 0);

    const saveReservationData = (data) => {
        setReservationData(data);
    };

    // Fonction pour caster une string en date s'il le faut
    const castToDate = (date) => {
        if (typeof date === "string") {
            // if date has " at the beginning and end, remove them
            if (date.charAt(0) === '"' && date.charAt(date.length - 1) === '"') {
                return parseISO(date.substring(1, date.length - 1));
            }
            return parseISO(date);
        }
        return date;
    }

    // Formatage des dates pour réutilisation si nécessaire
    let formattedDates = [];
    if (selectedDates[0]) {
        formattedDates = selectedDates.map((date) =>
            date ? format(castToDate(date), "dd/MM/yyyy") : format(castToDate(selectedDates[0]), "dd/MM/yyyy")
        );
    }

    //let formattedDate = selectedDate ? format(castToDate(selectedDate), "dd/MM/yyyy") : format(castToDate(new Date()), "dd/MM/yyyy");

    const resetContext = () => {
        setSalle(null);
        setImages([]);
        setSelectedStartMonth("");
        setSelectedEndMonth("");
        setSelectedOptionType("");
        setSelectedOptionValue("");
        setSalleId(0);
        setNbPostes(1);
        setSelectedDates([null, null]);
        setOpenDaysCount(0);
        setSelectedMonths(0);
        setIsUserLoggedIn(!!token);
        setSelectedStartTime("");
        setSelectedEndTime("");
        setSelectedHourCount(0);
        setSelectedDay(null);
        setEarliestHour("");
        setLatestHour("");
        setSelectedDate(new Date());
        setSelectedTimeRanges([]);
        setStartIndex(-1);
        setEndIndex(-1);
        setStartYearValue(0);
        setEndYearValue(0);
        setReservationData(null);
        setType([]);
        setReinitData(false);
        setEquipements(null);
        setPrixSelectionnes([]);
        setTotalPrixEquipements(0);
        setSelectedServices([]);
        setTotalPrixServices(0);
    };

    // Fonction pour afficher un texte avec les retours à la ligne qu'il contient
    const renderTextWithLineBreaks = (text) => {
        if (!text) {
            return null;
        }
        const textArray = text.split("\n");
        return textArray.map((line, index) => (
            <div key={index}>{line}</div>
        ));
    };

    return (
        <MyContext.Provider
            value={{
                resetContext,
                nb_postes,
                setNbPostes,
                selectedDates,
                formattedDates,
                //formattedDate,
                setSelectedDates,
                openDaysCount,
                setOpenDaysCount,
                setSelectedMonths,
                selectedMonths,
                selectedStartTime,
                setSelectedStartTime,
                selectedEndTime,
                setSelectedEndTime,
                selectedHourCount,
                setSelectedHourCount,
                selectedDay,
                setSelectedDay,
                earliestHour,
                setEarliestHour,
                setLatestHour,
                latestHour,
                selectedDate,
                setSelectedDate,
                setSelectedTimeRanges,
                selectedTimeRanges,
                reservationData,
                saveReservationData,
                selectedOptionType,
                setSelectedOptionType,
                selectedOptionValue,
                setSelectedOptionValue,
                salleId,
                setSalleId,
                selectedStartMonth,
                setSelectedStartMonth,
                selectedEndMonth,
                setSelectedEndMonth,
                salle,
                setSalle,
                images,
                setImages,
                isUserLoggedIn,
                setIsUserLoggedIn: setIsUserLoggedIn,
                startIndex,
                setStartIndex,
                endIndex,
                setEndIndex,
                startYearValue,
                setStartYearValue,
                endYearValue,
                setEndYearValue,
                type,
                setType,
                reinitData,
                setReinitData,
                equipements,
                setEquipements,
                prixSelectionnes,
                setPrixSelectionnes,
                totalPrixEquipements,
                setTotalPrixEquipements,
                token,
                setToken,
                userRole,
                setUserRole,
                userInfos,
                setUserInfos,
                renderTextWithLineBreaks,
                castToDate,
                redirectIfNotAdmin,
                selectedServices,
                setSelectedServices,
                totalPrixServices,
                setTotalPrixServices
            }}
        >
            {children}
        </MyContext.Provider>
    );
};

MyContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useMyContext = () => useContext(MyContext);
export {MyContext, MyContextProvider, useMyContext};
