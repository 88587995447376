import React, {useState} from "react";
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import "../../../style/contact-form.css";
import axios from "axios";
import PropTypes from "prop-types";

const ContactForm = (props) => {
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        message: "",
        salle: props.salleId,
    });

    const [isModalOpen, setModalOpen] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;

        try {
            const sendMailReponse = await axios.post(`${apiUrl}/sendMail`, formData);

            // Ouvrir la modale après la soumission réussie
            setModalOpen(true);

            // Réinitialiser le formulaire
            setFormData({
                nom: "",
                prenom: "",
                email: "",
                telephone: "",
                message: "",
                salle: props.salleId,
            });
        } catch (error) {
            console.error("Erreur lors de l'envoi de la demande :", error);
        }
    };

    const handleCloseModal = () => {
        // Fermer la modale
        setModalOpen(false);
    };

    return (
        <Container className={"infos_salle_right"}>
            <Typography variant="h5" align="left" className={"sous-titres"} gutterBottom>
                Prise de Contact
            </Typography>
            {/*Préciser que tous les champs sont requis*/}
            <Typography variant="body2" align="left" gutterBottom>
                Tous les champs sont requis
            </Typography>
            <form onSubmit={handleSubmit} className={"contact-form"}>
                <Grid container spacing={2}>
                    <TextField
                        fullWidth
                        label="Nom"
                        name="nom"
                        value={formData.nom}
                        onChange={handleChange}
                        variant={"standard"}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Prénom"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                        variant={"standard"}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        variant={"standard"}
                    />
                    <TextField
                        fullWidth
                        label="Téléphone"
                        name="telephone"
                        value={formData.telephone}
                        onChange={handleChange}
                        variant={"standard"}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        variant={"standard"}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" className={"send-form"}>
                        Envoyer
                    </Button>
                </Grid>
            </form>

            {/* Modale de confirmation */}
            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>Message transmis avec succès</DialogTitle>
                <DialogContent>
                    <Typography>Votre message a été transmis avec succès.<br/>
                        Vous serez recontacté très bientôt !</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

ContactForm.propTypes = {
    salleId: PropTypes.number.isRequired,
}
export default ContactForm;
