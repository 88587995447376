import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useMyContext} from "../MyContext";
import {useParams} from "react-router-dom";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CoworkingBilling() {
    const {salleId} = useParams(); // Récupération de l'ID depuis l'URL

    const [daysUsed, setDaysUsed] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [dailyPrice, setDailyPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const {token} = useMyContext();

    useEffect(() => {
        const fetchSalleDetails = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/espaces/${salleId}`);
                setDailyPrice(parseFloat(response.data.salle.prix_journee));
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des détails de la salle:",
                    error
                );
            }
        };

        fetchSalleDetails();
    }, [salleId]);

    const handleDaysChange = (e) => {
        const days = parseInt(e.target.value) || 0; // Si la valeur est NaN, remplace par 0
        setDaysUsed(days);
        setTotalPrice(days * dailyPrice);
    };

    const handleConfirm = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleGenerateInvoice = () => {
        setOpenDialog(false);
        setLoading(true);

        const apiUrl = process.env.REACT_APP_API_URL;
        const invoice = {
            days: daysUsed,
            price: totalPrice,
        };

        axios.post(`${apiUrl}/coworkingbilling`, invoice, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(() => {
            setSnackbarMessage("Facture générée avec succès");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setDaysUsed("");
            setTotalPrice(0);
        }).catch((error) => {
            console.error("Erreur lors de la génération de la facture:", error);
            setSnackbarMessage("Erreur lors de la génération de la facture");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    // Calcul du prix TTC
    const totalPriceTTC = totalPrice * 1.2;

    return (
        <Box
            sx={{
                maxWidth: 400,
                mx: "auto",
                mt: 4,
                p: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography variant="h5" gutterBottom>
                Facturer les jours passés
            </Typography>
            <TextField
                label="Nombre de jours"
                type="number"
                value={daysUsed}
                onChange={handleDaysChange}
                fullWidth
                variant="outlined"
                margin="normal"
                inputProps={{min: 0}}
            />
            <Typography variant="h6">
                Total : {totalPrice.toFixed(2)}€ HT / {totalPriceTTC.toFixed(2)}€ TTC
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                fullWidth
                sx={{mt: 2}}
                disabled={loading || !daysUsed}
                startIcon={loading && <CircularProgress size={20} />}
            >
                {loading ? "En cours..." : "Générer la facture"}
            </Button>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir générer la facture pour {daysUsed} jours ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleGenerateInvoice} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
