import '../style/NotFound.css';
import {replace} from "formik";
import {useNavigate} from "react-router-dom";

export default function NotFound(){
    const navigate = useNavigate();

    return(
      <div className="wrap-notfound">
          <h1>Cette page ne semble pas exister.</h1>
          <p className="redirect"><a href="/">Retour sur la page de réservation</a></p>
      </div>
    );
}