import axios from "axios";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "../style/photosCarousel.css";
import { useMyContext } from "../MyContext";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

Modal.setAppElement('#root');

export default function PhotosCarousel() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const photosApi = axios.create({
        baseURL: `${apiUrl}`,
    });
    const { token } = useMyContext();

    const [photos, setPhotos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const responsePhotos = await photosApi.get("/photos_carousel", {
                    headers: { Authorization: "Bearer " + token }
                });
                const sortedPhotos = responsePhotos.data.sort((a, b) => a.id - b.id);
                setPhotos(sortedPhotos);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des photos.", error);
            }
        };

        fetchPhotos();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: () => setDragging(true),
        afterChange: () => setDragging(false),
    };

    const openModal = (photo) => {
        if (!dragging) {
            setSelectedPhoto(photo);
            setModalIsOpen(true);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedPhoto(null);
    };

    const handleKeyDown = (event, photo) => {
        if ((event.key === 'Enter' || event.key === ' ') && !dragging) {
            openModal(photo);
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.className.includes("modalOverlay")) {
            closeModal();
        }
    };

    return (
        <div className="carouselContainer">
            
            <h2><b>Un petit aperçu de notre vie à l’Usine …</b></h2>
            <p>Repas en commun, espaces pour se détendre, pour se rencontrer, pour bricoler ensemble… l’Usine permet de déconnecter entre deux réunions, de découvrir des solutions entre deux viennoiseries… <br></br>Magique.<br></br>

Ces espaces ne sont pas à louer, ils sont trop précieux. Par contre ils peuvent devenir les tiens si tu deviens résident ou résidente, prêt.e pour l’aventure ?</p>
            <Slider {...settings}>
                {photos.map((photo) => (
                    <div
                        key={photo.id}
                        className="carouselItem"
                        role="button" // Ajout du rôle pour indiquer que c'est un élément interactif
                        tabIndex={0} // Rendre l'élément focusable avec le clavier
                        onClick={() => openModal(photo)} 
                        onKeyDown={(event) => handleKeyDown(event, photo)} // Gérer les événements clavier
                    >
                        <div className="image-wrap">
                            <img
                                src={photo.url}
                                alt={photo.title}
                                className="carouselImage"
                            />
                        </div>
                        <div className="photoTitle">
                            <p>{photo.title}</p>
                        </div>
                    </div>
                ))}
            </Slider>

            {selectedPhoto && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Agrandir l'image"
                    className="modal"
                    overlayClassName="modalOverlay"
                    onClick={handleClickOutside}
                >
                    <div className="modalContent">
                        <img src={selectedPhoto.url} alt={selectedPhoto.title} className="modalImage" />
                        <button onClick={closeModal} className="closeButton">Fermer</button>
                    </div>
                </Modal>
            )}
        </div>
    );
}
