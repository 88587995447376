import React, { useContext, useEffect, useState } from "react";
import Calendar from "@demark-pro/react-booking-calendar";
import { MyContext } from "../../MyContext";
import "../../style/calendars/calendarDay.css";
import { isAfter, isBefore } from "date-fns";
import frLocale from "date-fns/locale/fr";
import PropTypes from "prop-types";

export default function CalendarDay({ events }) {
  const { selectedDates, setSelectedDates, setOpenDaysCount, type } =
    useContext(MyContext);
  const [calendarEvents, setCalendarEvents] = useState({ events });

  useEffect(() => {
    let openDaysCount = 0;

    if (selectedDates[0] && selectedDates[1]) {
      openDaysCount = countOpenDays(
        selectedDates[0],
        selectedDates[1],
        getReservedDates()
      );
    } else if (selectedDates[0] || selectedDates[1]) {
      openDaysCount = 1;
    }

    setOpenDaysCount(openDaysCount);
  }, [selectedDates, calendarEvents]);

  const handleChange = (e) => {
    // Si deux dates sont déjà sélectionnées, on les remplace par la nouvelle date
    if (selectedDates[0] !== null && selectedDates[1] !== null) {
      setSelectedDates([e[1], null]);
    } else {
      setSelectedDates(e);
    }

    if (selectedDates[0] !== null) {
      // Si la deuxième date est antérieure à la première, inverser l'ordre
      if (isBefore(e[1], e[0])) {
        setSelectedDates([e[1], null]);
      }
    } else {
      setSelectedDates(e);
    }
  };

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0;
  };

  function isWeekday(date) {
    const day = date.getDay();
    return day >= 1 && day <= 5; // Du lundi (1) au vendredi (5)
  }

  function countOpenDays(startDate, endDate, reservedDates) {
    const oneDay = 24 * 60 * 60 * 1000;
    let currentDate = new Date(startDate);
    const targetDate = new Date(endDate);
    let count = 0;

    while (currentDate <= targetDate) {
      const dateString = currentDate.toISOString().split("T")[0];
      if (!reservedDates.includes(dateString)) {
        count++;
      }
      currentDate.setTime(currentDate.getTime() + oneDay);
    }

    return count;
  }

  useEffect(() => {
    const dayCells = document.querySelectorAll(".css-p6g64r-DayCell");
    dayCells.forEach((dayCell) => {
      const children = dayCell.querySelectorAll(".css-img1gn-Et");
      children.forEach((child) => {
        child.classList.remove("css-img1gn-Et");
        child.classList.add("css-16ni9cx-DayCellContent");
      });
    });
  }, [selectedDates]);

  useEffect(() => {
    //console.log("Liste d'évènements:", calendarEvents);

    if (
      calendarEvents &&
      calendarEvents.events &&
      Array.isArray(calendarEvents.events.events)
    ) {
      calendarEvents.events.events.forEach((event) => {
        const eventDate = new Date(event.googleEvent.start.dateTime);
      });
    }
  }, [calendarEvents]);

  function getReservedDates() {
    if (
      calendarEvents &&
      calendarEvents.events &&
      Array.isArray(calendarEvents.events.events)
    ) {
      return calendarEvents.events.events.map((event) => {
        const eventDate = new Date(event.googleEvent.start.dateTime);
        eventDate.setDate(eventDate.getDate() - 1);
        return eventDate.toISOString().split("T")[0];
      });
    }
    return [];
  }

  const [translate, setTranslate] = useState(false);
  useEffect(() => {
    const containers = document.querySelectorAll(".calendar-container");
    containers.forEach((container) => {
      const span = container.querySelector("span");
      if (span && span.textContent) {
        const englishMonth = span.textContent.trim();
        span.textContent = getFrenchMonth(englishMonth);
      }
    });
  }, [translate]);

  // Fonction pour obtenir le mois en français à partir du mois en anglais
  const getFrenchMonth = (englishMonth) => {
    const monthsMap = {
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
    };
    return monthsMap[englishMonth] || englishMonth;
  };

  // Fonction pour ajuster la date en soustrayant un jour
  function getPreviousDay(date) {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() - 1);
    return adjustedDate;
  }

  return (
    <div className="calendar-container">
      <Calendar
        selected={selectedDates}
        onChange={handleChange}
        onMonthChange={() => {
          setTranslate(!translate);
        }}
        onOverbook={(e, err) => alert("Impossible de réserver cette date")}
        components={{
          // eslint-disable-next-line react/prop-types
          DayCellFooter: ({ innerProps }) => <div {...innerProps}></div>,
        }}
        locale={{ frLocale }}
        // Dans la fonction disabled
        disabled={(date, state) => {
          const dateString = date.toISOString().split("T")[0];

          // Si la salle est réservable par poste (coworking par exemple) et n'est pas avant hier
          if (type.reservable_par_poste && !isBefore(date, yesterday)) {
            // Compte le nombre de réservations pour la date donnée
            const reservationsForDate = calendarEvents.events.events.filter(
              (event) => {
                const eventDateTime = new Date(event.googleEvent.start.date);
                const eventDateString = getPreviousDay(eventDateTime)
                  .toISOString()
                  .split("T")[0];
                return eventDateString === dateString;
              }
            ).length;

            if (reservationsForDate >= 8) {
              return true;
            }

            return false; // Ne pas désactiver si moins de 3 réservations
          }

          // Vérifie si la date est hors du mois, est un week-end ou est avant hier
          if (isBefore(date, yesterday)) {
            return true;
          }

          // Vérifie si des événements existent et sont un tableau non vide
          if (
            calendarEvents &&
            calendarEvents.events &&
            Array.isArray(calendarEvents.events.events) &&
            calendarEvents.events.events.length > 0
          ) {
            // Vérifie si la date fournie est incluse dans la plage d'un événement avec une date de début et de fin définies
            const isEventDate = calendarEvents.events.events.some((event) => {
              const eventStartDate = new Date(event.googleEvent.start.date);
              const eventEndDate = new Date(event.googleEvent.end.date);

              // Ajouter ou retirer un jour à la date de fin pour inclure cette date dans la plage
              eventStartDate.setDate(eventStartDate.getDate() - 1);
              eventEndDate.setDate(eventEndDate.getDate() - 1);

              // Vérifie si la date fournie est incluse dans la plage de l'événement
              return (
                isBefore(date, eventEndDate) && isAfter(date, eventStartDate)
              );
            });

            // Vérifie si la date fournie correspond à une date d'un événement avec une heure de début définie
            const isEventDateTime = calendarEvents.events.events.some(
              (event) => {
                const eventDateTime = new Date(
                  event.googleEvent.start.dateTime
                );
                eventDateTime.setDate(eventDateTime.getDate() - 1); // Soustraction d'un jour à la date de l'événement

                const eventDateString = eventDateTime
                  .toISOString()
                  .split("T")[0];

                // Vérifie si la date fournie est la même que celle de l'événement
                return eventDateString === dateString;
              }
            );

            if (isEventDate || isEventDateTime) {
              return true; // Désactiver les dates d'événement
            }
          }

          return false;
        }}
        variant="events"
        dateFnsOptions={{ weekStartsOn: 1, locale: frLocale }}
        range={true}
      />
    </div>
  );
}

CalendarDay.propTypes = {
  events: PropTypes.array,
};
