import React, {useEffect, useState} from "react";
import axios from "axios";
import {Form, FormikProvider, useFormik} from "formik";
import {LoadingButton} from "@mui/lab";
import {Chip, TextField, Autocomplete} from "@mui/material";
import PropTypes from "prop-types";
import {useMyContext} from "../MyContext";

// This component needs a route to the API that sends the table of items with many-to-many relationship
// It displays a form with a list of items containing a list of other items to select from
//TODO : Add a way to select the items to link

export default function GenericManyToManyForm(props) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const {token} = useMyContext();
    const [isLoading, setIsLoading] = useState(true)

    const [linkItems, setLinkItems] = useState([]);
    const [linkItemsAndSelectedOptions, setLinkItemsAndSelectedOptions] = useState([]);

    const [options, setOptions] = useState([]);

    // Vérifier que les donnés sont bien chargées
    useEffect(() => {
        if (linkItems.length > 0 && options.length > 0) {
            //console.log(options);
            /*linkItems.map((item) => (
                console.log(
                    item[props.secondItemsName].map((item2) => item2[props.secondItemsTitle]))
                )
            );*/
            setIsLoading(false);
        }
    }, [linkItems, options]);

    useEffect(() => {
        const linkItemsRequest = axios.create({
            baseURL: `${apiUrl}/${props.linkItemsName}`,
            headers: {
                Authorization: "Bearer " + token,
            }
        });

        try {
            linkItemsRequest.get('').then((response) => {
                setLinkItems(response.data);
            });
        } catch (error) {
            console.error("Une erreur s'est produite : ", error);
        }
        // Récupérer les items à sélectionner
        axios.get(`${apiUrl}/${props.secondItemsName}`, {headers: {Authorization: "Bearer " + token}})
            //.then(response => setOptions(response.data.map((item)=> item[props.secondItemsTitle]) ))
            .then(response => setOptions(response.data))
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs :', error));
    }, []);

    const generateInitialValues = () => {
        // TODO : Générer les valeurs initiales
    };

    const initialValues = generateInitialValues();

    const formik = useFormik({
        initialValues,
        onSubmit: async (values) => {
            try {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(),
                };

                const response = await fetch(`${apiUrl}/${props.linkItemsName}`, requestOptions);
                const data = await response.json();
                //console.log(data);
            } catch (error) {
                console.error(error);
            }
        },
    });

    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} =
        formik;

    if (isLoading) {
        return (<div>Chargement...</div>);
    } else {
        return (
            <div>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        {linkItems.map((item) => (
                            <div key={item.id}>
                                <h2>{item[props.title]}</h2>
                                <Autocomplete
                                    multiple
                                    id={`item-${item.id}`}
                                    options={options}
                                    // Les valeurs pré-sélectionnées sont celles qui sont déjà liées à l'item
                                    value={item[props.secondItemsName]}
                                    isOptionEqualToValue={(options, value) => options[0] === value.id}
                                    getOptionLabel={(option) => option[props.secondItemsTitle]}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(`item-${item.id}`, newValue.map((choosed) => choosed.id));
                                        setLinkItemsAndSelectedOptions(newValue);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, tagIndex) => (
                                            <Chip
                                                key={tagIndex}
                                                label={option[props.secondItemsTitle]}
                                                {...getTagProps({index: tagIndex})}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={props.secondItemsName}
                                            error={Boolean(touched[props.secondItemsName] && errors[props.secondItemsName])}
                                            helperText={touched[props.secondItemsName] && errors[props.secondItemsName]}
                                        />
                                    )}
                                />
                            </div>
                        ))}
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            id="register_button"
                        >
                            Enregistrer
                        </LoadingButton>
                    </Form>
                </FormikProvider>

            </div>
        );
    }
}

GenericManyToManyForm.propTypes = {
    linkItemsName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    secondItemsName: PropTypes.string.isRequired,
    secondItemsTitle: PropTypes.string.isRequired
};