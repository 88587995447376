import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {Card, CardMedia, Grid} from "@mui/material";
import "../style/salleDetails.css";
import GoogleMap from "../components/GoogleMap";
import IncrementDecrementComponent from "../components/IncrementDecrementComponent";
import HorizontalSeparator from "../components/separators/HorizontalSeparator";
import DashedHorizontalSeparator from "../components/separators/DashedHorizontalSeparator";
import HandleHourDayMonth from "../components/HandleHourDayMonth";
import CalendarDay from "../components/calendars/CalendarDay";
import {useMyContext} from "../MyContext";
import CalendarMonth from "../components/calendars/CalendarMonth";
import CalendarHour from "../components/calendars/CalendarHour";
import "../App.css";
import {format} from "date-fns";
import frLocale from "date-fns/locale/fr";
import AddEquipements from "../components/AddEquipements";
import Modal from "react-modal";
import PointsTurning from "../components/loading/PointsTurning";
import ContactForm from "../components/forms/contact/ContactForm";
import DCarousel from "../components/DCarousel";

import Button from "@mui/material/Button";
import ServiceSelector from "../components/ServiceSelector";


export default function SalleDetails() {
    const {
        nb_postes,
        type,
        setType,
        formattedDates,
        openDaysCount,
        selectedMonths,
        earliestHour,
        latestHour,
        selectedDate,
        selectedTimeRanges,
        setSelectedOptionValue,
        setSalleId,
        setSalle,
        setImages,
        salle,
        images,
        resetContext,
        prixSelectionnes,
        totalPrixEquipements,
        renderTextWithLineBreaks,
        selectedStartMonth,
        selectedEndMonth,
        selectedStartYear,
        selectedEndYear,
        selectedServices,
        totalPrixServices
    } = useMyContext(); // Utilisez useMyContext pour accéder au contexte

    const [events, setEvents] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [reset, setReset] = useState(false);
    const [avantages, setAvantages] = useState([]);

    const { salleId: routeSalleId } = useParams(); // Renommer la variable pour éviter le conflit
    const navigate = useNavigate();

    const navigateToBilling = () => {
        navigate(`/coworkingbilling/${routeSalleId}`); // Utilise la nouvelle variable pour la redirection
    };

    // Réinitialiser les données
    useEffect(() => {
        resetContext();
        setReset(true);
    }, []);

    // Function to open the modal and set the selected image
    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };


    const isButtonDisabled = () => {
        return (
            (!selectedTimeRanges || selectedTimeRanges.length === 0) &&
            (!openDaysCount || openDaysCount === 0) &&
            (!selectedMonths || selectedMonths === 0)
        );
    };

    // Récupérer le paramètre "salleId" depuis l'URL grâce au hook useParams de React Router
    const {salleId} = useParams();

    const handleSelectOption = (option) => {
        setSelectedOption(option);
    };

    const handleReserver = () => {
        setSelectedOptionValue(selectedOption);
        setSalleId(salleId);
    };

    // Utiliser useEffect pour charger les détails de la salle dès que le composant est monté
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        if (salle && salle.google_calendar !== "" && salle.google_calendar !== null && salle.google_calendar !== undefined) {
            const getEventsForSalle = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/events/${salleId}`);
                    const {data} = response;
                    setEvents(data);
                } catch (error) {
                    console.error(error);
                }
            };

            getEventsForSalle();
        }
        // Récupérer les types de salles
        const typesResponse = axios
            .get(`${apiUrl}/espaces/typeespace/${salleId}`)
            .then((type_r) => {
                setType(type_r.data);
            });

    }, [salleId]);


    useEffect(() => {
        if (type.length !== 0) {
            setOptions(
                [
                    type.reservable_par_heure
                        ? {label: "à l'heure", value: "heure"}
                        : null,
                    type.reservable_par_jour
                        ? {label: "à la journée", value: "jour"}
                        : null,
                    type.reservable_par_mois ? {label: "au mois", value: "mois"} : null,
                ].filter(Boolean)
            );
        }
    }, [type]);

    // Si options contient des valeurs
    useEffect(() => {
        if (options.length !== 0) {
            setSelectedOption(options[0].value);
        } else {
            setSelectedOption("");
        }
    }, [options, reset]);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const fetchRoomDetails = async () => {
            try {
                // Faire une requête GET à l'API pour récupérer les détails de la salle avec l'ID "salleId"
                const response = await axios.get(`${apiUrl}/espaces/${salleId}`);
                const salleAvecImages = response.data;

                // Mettre à jour les states "salle" et "images" et type avec les données récupérées
                setSalle(salleAvecImages.salle);
                setImages(salleAvecImages.images);
            } catch (error) {
                console.error(error);
            }
        };

        fetchRoomDetails();

        // Récupérer les avantages de la salle

        const avantagesResponse = axios
            .get(`${apiUrl}/avantages/espace/${salleId}`)
            .then((avantages_r) => {
                setAvantages(avantages_r.data);
            });
    }, [salleId]);

    // Afficher "Chargement..." tant que les détails de la salle sont en cours de chargement
    if (!salle || !reset) {
        return <PointsTurning/>;
    } else {
        return (
            <div className="salle_details">
                <div className="salle_details_top">

                    <h1 className="titre desktop">{salle.nom}</h1>
                    {salle.nom.toLowerCase().includes("coworking") && (
                        <button
                            className="reserver"
                            onClick={navigateToBilling}
                        >
                            Demander une facture pour les jours passés
                        </button>
                    )}
                </div>

                {/* Afficher la carte principale de la salle */}
                <Grid container spacing={2} className="images-wrapper desktop">
                    <Grid item xs={12} md={6}>
                        <Card
                            onClick={() =>
                                openModal({url: salle.image_principale, id: "principal"})
                            }
                            className={"image-card"}
                        >
                            <CardMedia
                                component="img"
                                height="420"
                                image={salle.image_principale}
                                alt={salle.nom}
                                sx={{borderRadius: "2px", cursor: "pointer"}}
                            />
                        </Card>
                    </Grid>

                    {/* Afficher les autres images de la salle dans une grille */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            {images
                                .filter((image) => image.url !== salle.image_principale)
                                .slice(0, 4)
                                .map((image) => (
                                    <Grid item xs={6} key={image.id}>
                                        <Card
                                            className={"image-card"}
                                            onClick={() => openModal(image)}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={image.url}
                                                alt={image.id}
                                                sx={{borderRadius: "2px", cursor: "pointer"}}
                                            />
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={"mobile"}>
                    {/*Carousel for mobile (Envoyer directement l'url de images)*/}
                    <DCarousel
                        urls={images.length > 0 ? images.map((img) => img.url) : []}
                        items={[]}
                    />
                    {/*console.log(images.map((img) => img.url))*/}
                    <h1 className="titre">{salle.nom}</h1>
                </Grid>

                {/* Image Modal using react-modal */}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Selected Image"
                    style={{
                        overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                        content: {
                            width: "70%",
                            maxHeight: "80vh",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        borderRadius: "2px",
                    }}
                >
                    {selectedImage && (
                        <img
                            src={selectedImage.url}
                            alt={selectedImage.id}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "2px",
                            }}
                        />
                    )}
                    {/* <button onClick={closeModal}>Close Modal</button> */}
                </Modal>

                {/* Afficher les informations sur la salle */}

                <div className="infos_salle">
                    <div className="infos_salle_left">
                        {/* Afficher les tarifs de la salle */}
                        {Boolean(type.reservable_en_ligne) ? (
                            <div className="prix">
                                {Boolean(type.reservable_par_heure) && (
                                    <div>
                                        <p className={"sous-titres"}>Heure</p>
                                        <p>{salle.prix_heure}€ HT</p>
                                    </div>
                                )}
                                {Boolean(type.reservable_par_jour) && (
                                    <div>
                                        <p className={"sous-titres"}>Journée</p>
                                        <p>{salle.prix_journee}€ HT</p>
                                    </div>
                                )}
                                {Boolean(type.reservable_par_mois) && (
                                    <div>
                                        <p className={"sous-titres"}>Mois</p>
                                        <p>{salle.prix_mois}€ HT</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="devis">
                                <p>Tarification</p>
                                <p>Sur devis</p>
                            </div>
                        )}
                        <HorizontalSeparator/>
                        {/* Afficher d'autres informations sur la salle */}
                        <div className="informations">
                            <div>
                                <b className={"sous-titres"}>{salle.description}</b>
                                <br></br>
                                {renderTextWithLineBreaks(salle.details)}
                            </div>
                            <HorizontalSeparator/>
                            <p>
                                Cette salle peut accueillir jusqu'à <b
                                style={{whiteSpace: 'nowrap'}}>{salle.jauge} personnnes .</b>
                            </p>
                            {avantages.length > 0 && (
                                <div className={"avantages"}>
                                    <p>
                                        <b className={"sous-titres"}>
                                            Sont compris avec la location <span style={{whiteSpace: 'nowrap'}}>de l'espace :</span>
                                        </b>
                                    </p>
                                    <ul>
                                        {
                                            // Afficher les avantages de la salle
                                            avantages.map((avantage) => (
                                                <li key={avantage.id}>{avantage.nom}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )}

                            {Boolean(type.reservable_en_ligne) && (
                                <>
                                    <HorizontalSeparator/>
                                    <div className="handleandincrement">
                                        <div className={"duration-wapper"}>
                                            <HandleHourDayMonth
                                                options={options}
                                                onSelectOption={handleSelectOption}
                                            />
                                        </div>
                                        <div>
                                            {Boolean(type.reservable_par_poste) && (
                                                <div className="postes-wrap">
                                                    <IncrementDecrementComponent className="pour-responsive"/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <HorizontalSeparator/>
                        {/* Placeholder pour les calendriers */}
                        <div className="left-panel">
                            {/* Affichez le contenu en fonction de l'option sélectionnée */}
                            {selectedOption === "heure" && (
                                <div>
                                    <CalendarHour events={events}/>
                                </div>
                            )}
                            {selectedOption === "jour" && (
                                <div>
                                    <CalendarDay events={events}/>
                                </div>
                            )}
                            {selectedOption === "mois" && (
                                <div>
                                    <CalendarMonth events={events}/>
                                </div>
                            )}
                            {Boolean(type.reservable_avec_equipement) && (<HorizontalSeparator/>)}
                            {Boolean(type.reservable_avec_equipement) && <AddEquipements/>}
                        </div>
                    </div>
                    {Boolean(type.reservable_en_ligne) ? (
                        <div className="infos_salle_right">
                            {/*selecteur heure/jour/mois*/}
                            {selectedOption === "heure" && (
                                <>
                                    <h5 className={"sous-titres"}>Location à l'heure</h5>
                                    <div className="date_horaire">
                                        <div>
                                            <p className="">
                                                <b>Date</b>
                                                <br/>
                                                {format(selectedDate, "d MMMM yyyy", {
                                                    locale: frLocale,
                                                })}
                                            </p>
                                        </div>
                                        {earliestHour && (
                                            <div>
                                                <p className="">
                                                    <b>Horaire</b>
                                                    <br/>
                                                    De {earliestHour}h à {latestHour}h
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {selectedOption === "jour" && (
                                <>
                                    <h5 className={"sous-titres"}>Location à la journée</h5>
                                    <div className="reserved-dates">

                                        {formattedDates[0] && (
                                            <div>
                                                <p>
                                                    Du<br></br>
                                                    {formattedDates[0]}
                                                </p>
                                            </div>
                                        )}
                                        {formattedDates[1] && (
                                            <div>
                                                <p>
                                                    Au<br></br>
                                                    {formattedDates[1]}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {selectedOption === "mois" && (
                                <>
                                    <p className={"sous-titres"}>Location au mois</p>
                                    <div className="date_horaire">
                                        De {selectedStartMonth} {selectedStartYear} à {selectedEndMonth} {selectedEndYear}
                                    </div>
                                </>
                            )}

                            <DashedHorizontalSeparator/>
                            <h5 className="sous-titres pt-3 pb-3">
                                Récapitulatif
                            </h5>
                            <div className="recap">
                                {selectedOption === "heure" && (
                                    <>
                                        <div className="heure">
                                            <p>{latestHour - earliestHour} heure(s)</p>
                                            {Boolean(type.reservable_par_poste) && (
                                                <p>{nb_postes} poste(s)</p>
                                            )}
                                        </div>
                                        <div className="poste">
                                            <p>
                                                {salle.prix_heure}€ HT X {latestHour - earliestHour}
                                            </p>
                                            {Boolean(type.reservable_par_poste) && (
                                                <p id="nb_postes">x{nb_postes}</p>
                                            )}
                                        </div>
                                    </>
                                )}

                                {selectedOption === "jour" && (
                                    <>
                                        <p>{openDaysCount} journée(s)</p>
                                        <p>
                                            {openDaysCount * salle.prix_journee}€ HT{" "}
                                            {Boolean(type.reservable_par_poste) && (
                                                <em>X {nb_postes} poste(s)</em>
                                            )}
                                        </p>
                                    </>
                                )}

                                {selectedOption === "mois" && (
                                    <>
                                        <p>{selectedMonths} mois</p>
                                        <p>
                                            {selectedMonths * salle.prix_mois}€ HT{" "}
                                            {Boolean(type.reservable_par_poste) && (
                                                <em>X {nb_postes} poste(s)</em>
                                            )}
                                        </p>
                                    </>
                                )}
                            </div>

                            {prixSelectionnes.length > 0 && (
                                <div>
                                    <p id="supplement" className={"sous-titres"}>
                                        Option(s)
                                    </p>
                                    {prixSelectionnes.map((equipement) => (
                                        <div className="recap" key={equipement.nom}>
                                            <p>{equipement.nom}</p>
                                            <p>+ {equipement.prix}€</p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedServices.length > 0 && (
                                <div>
                                    <p id="supplement" className={"sous-titres"}>
                                        Services
                                    </p>
                                    {selectedServices.map((service) => (
                                        <div className="recap" key={service.service.nom}>
                                            <p>{service.service.nom}</p>
                                            <p>+ {service.service.prix_unitaire * service.people}€</p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <HorizontalSeparator/>

                            <div className="total">
                                <p className={"sous-titres"}>Total</p>
                                {selectedOption === "heure" && (
    <div>
        <p className={"sous-titres"}>
            {type.reservable_par_poste
                ? (salle.prix_heure *
                nb_postes *
                (latestHour - earliestHour) +
                totalPrixEquipements + totalPrixServices).toFixed(2)
                : (salle.prix_heure * (latestHour - earliestHour) +
                totalPrixEquipements + totalPrixServices).toFixed(2)}
            € HT
        </p>
        <p className={"sous-titres"}>
            {type.reservable_par_poste
                ? ((salle.prix_heure *
                nb_postes *
                (latestHour - earliestHour) +
                totalPrixEquipements + totalPrixServices) * 1.2).toFixed(2)
                : ((salle.prix_heure * (latestHour - earliestHour) +
                totalPrixEquipements + totalPrixServices) * 1.2).toFixed(2)}
            € TTC
        </p>
    </div>
)}
{selectedOption === "jour" && (
    <div>
        <p className={"sous-titres"}>
            {type.reservable_par_poste
                ? (salle.prix_journee * nb_postes * openDaysCount +
                totalPrixEquipements + totalPrixServices).toFixed(2)
                : (salle.prix_journee * openDaysCount +
                totalPrixEquipements + totalPrixServices).toFixed(2)}
            € HT
        </p>
        <p className={"sous-titres"}>
            {type.reservable_par_poste
                ? ((salle.prix_journee * nb_postes * openDaysCount +
                totalPrixEquipements + totalPrixServices) * 1.2).toFixed(2)
                : ((salle.prix_journee * openDaysCount +
                totalPrixEquipements + totalPrixServices) * 1.2).toFixed(2)}
            € TTC
        </p>
    </div>
)}
                                {selectedOption === "mois" && (
                                    <p className={"sous-titres"}>
                                        {type.reservable_par_poste
                                            ? selectedMonths * salle.prix_mois * nb_postes +
                                            totalPrixEquipements
                                            : selectedMonths * salle.prix_mois + totalPrixEquipements}
                                        € HT
                                    </p>
                                )}
                            </div>

                            <Link
                                to={{
                                    pathname: "/reservation",
                                    state: {
                                        earliestHour: earliestHour,
                                    },
                                }}
                            >
                                <button
                                    className="reserver"
                                    onClick={handleReserver}
                                    disabled={isButtonDisabled()}
                                >
                                    Réserver la salle
                                </button>
                            </Link>
                        </div>
                    ) : (
                        <ContactForm salleId={salle.id}/>
                    )}

                </div>

                <HorizontalSeparator/>

                {/*Si c'est un type de salle reservable avec service*/}
                {Boolean(type.reservable_avec_service) && (
                    <div className={"services"}>
                        <h5 className={"sous-titres"}>Une petite faim ? On s'occupe de vous !</h5>
                        <ServiceSelector/>
                        <HorizontalSeparator/>
                    </div>
                )}

                <div className="infos_salle_bottom">
                    <GoogleMap/>
                </div>
            </div>
        );
    }
}