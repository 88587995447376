import React, { useState, useRef, useEffect } from "react";
import "../style/handleHourDayMonth.css";
import { useMyContext } from "../MyContext";
import { FormControl, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";

const HandleHourDayMonth = ({ options, onSelectOption }) => {
    const { setSelectedOptionType, setSelectedOptionValue, optionType } = useMyContext();
    const [selectedValue, setSelectedValue] = useState("heure");
    const selectRef = useRef(null); // Référence au Select

    const handleChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue(selectedOption);
        onSelectOption(selectedOption);
        setSelectedOptionType(optionType);
        setSelectedOptionValue(selectedOption);
    };

    useEffect(() => {
        // Fonction pour simuler un clic sur l'option "mois" puis revenir à "heure"
        const simulateClick = () => {
            if (selectRef.current) {
                // Sélectionne "mois"
                setSelectedValue("mois");
                onSelectOption("mois");
                setSelectedOptionType(optionType);
                setSelectedOptionValue("mois");

                // Utilise setTimeout pour ajouter un léger délai avant de revenir à "heure"
                setTimeout(() => {
                    setSelectedValue("heure");
                    onSelectOption("heure");
                    setSelectedOptionType(optionType);
                    setSelectedOptionValue("heure");
                }, 50); // Délai très court, ajustable si nécessaire
            }
        };

        simulateClick();
    }, []); // Déclenchement une seule fois après le montage

    return (
        <div className="handle_hour_day_month">
            <div>
                <p>Je souhaite louer</p>
            </div>
            <div style={{ marginLeft: "8px" }} className={"time-choice"}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ padding: "0", margin: "0" }}>
                    <Select
                        ref={selectRef} // Référence pour simuler le clic
                        style={{ cursor: "pointer" }}
                        value={selectedValue}
                        onChange={handleChange}
                        autoWidth
                        className="custom-select-style menu-item-style"
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value} className={"item"}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

HandleHourDayMonth.propTypes = {
    options: PropTypes.array.isRequired,
    onSelectOption: PropTypes.func.isRequired,
};

export default HandleHourDayMonth;
