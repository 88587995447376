import React, {useRef, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {
    Menu,
    MenuItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import Iconify from "../components/Iconify";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useMyContext} from "../MyContext";
import PropTypes from "prop-types";

export default function GenericMoreMenu(props) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const itemToDelete = props.itemId;
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    // Récupére la variable pour mettre à jour les données de la liste
    const {reinitData, setReinitData} = useMyContext();
    const {token} = useMyContext();

    const deleteItem = () => {
        axios
            .delete(`${apiUrl}/${props.item}/${props.itemId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                //console.log(response.data);
                alert("Élément supprimé avec succès");
                // Recharger la page
                setReinitData(!reinitData);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDeleteConfirmation = () => {
        // Cacher la boîte de dialogue de confirmation
        setShowConfirmation(false);
        // Appeler la fonction de suppression après confirmation
        deleteItem();
    };

    const handleDeleteButtonClick = () => {
        // Afficher la boîte de dialogue de confirmation
        setShowConfirmation(true);
    };

    const handleCancelConfirmation = () => {
        // Cacher la boîte de dialogue de confirmation
        setShowConfirmation(false);
    };

    return (
        <div>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: "100%"},
                }}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
            >
                <MenuItem
                    component={RouterLink}
                    to={"/form/" + props.item + "/" + props.itemId}
                    sx={{color: "text.secondary"}}
                >
                    <ListItemIcon>
                        <Iconify icon="eva:edit-fill" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Modifier"
                        primaryTypographyProps={{variant: "body2"}}
                        className="modifier"
                        data-testid="edit-option"
                    />
                </MenuItem>

                <MenuItem
                    sx={{color: "text.secondary"}}
                    onClick={handleDeleteButtonClick}
                >
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Supprimer"
                        primaryTypographyProps={{variant: "body2"}}
                        className="supprimer"
                        data-testid="delete-option"
                    />
                </MenuItem>
            </Menu>

            {/* Boîte de dialogue de confirmation */}
            {showConfirmation && (
                <Dialog open={showConfirmation} onClose={handleCancelConfirmation}>
                    <DialogTitle>Confirmation de suppression</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Êtes-vous sûr de vouloir supprimer cet élément ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelConfirmation} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleDeleteConfirmation} color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

GenericMoreMenu.propTypes = {
    itemId: PropTypes.number.isRequired,
    item: PropTypes.string.isRequired,
};