import GenericList from "../components/GenericList";
import GenericAddForm from "../components/forms/add/GenericAddForm";
import GenericManyToManyForm from "../components/GenericManyToManyForm";

export default function ManageRoles(){
    return(
        <div>
            <h1>Liste des rôles</h1>
            <GenericList item={"roles"} keysToExclude={["id", "created_at", "updated_at"]} />
            <h1>Ajouter un rôle</h1>
            <GenericAddForm item={"roles"} keysToExclude={["id", "created_at", "updated_at"]} />
            <h1>Attribuer un rôle</h1>
            <GenericManyToManyForm firstItemsName={"users"} secondItemsName={"roles"} linkItemsName={"roles_users"} title={"prenom"} secondItemsTitle={"nom"} keysToExclude={["id", "created_at", "updated_at"]} />
        </div>)
}