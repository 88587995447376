import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Products from "./pages/Products";
import ProfileForm from "./components/forms/modify/ProfileForm";
import ManageEspaces from "./pages/ManageEspaces";
import SalleDetails from "./pages/SalleDetails";
import EspaceForm from "./components/forms/modify/EspaceForm";
import SidebarAccount from "./layouts/dashboard/SidebarAccount";
import Factures from "./pages/account/Factures";
import Informations from "./pages/account/Informations";
import UploadImages from "./components/UploadImages";
import ListeEquipements from "./components/ListeEquipements";
import EquipementForm from "./components/forms/modify/EquipementForm";
import ManageDemandes from "./pages/ManageDemandes";
import ReservationForm from "./components/forms/ReservationForm";
import ManageTypesEspaces from "./pages/ManageTypesEspaces";
import GenericModifyForm from "./components/forms/modify/GenericModifyForm";
import ReservationSummary from "./components/ReservationSummary";
import {useMyContext} from "./MyContext";
import NotFound from "./pages/NotFound";
import ManageAvantages from "./pages/ManageAvantages";
import ManageUsers from "./pages/ManageUsers";
import ManageRoles from "./pages/ManageRoles";
import ManageUsersRoles from "./pages/ManageUsersRoles";
import Event from "./pages/Event";
import ChangePassword from "./pages/account/ChangePassword";
import CGV from "./pages/CGV";
import ForgotPassword from "./components/forms/password/ForgotPassword";
import ResetPassword from "./components/forms/password/ResetPassword";
import PhotosManager from "./components/PhotosManager";
import ManageServices from "./pages/ManageServices";
import CoworkingBilling from "./pages/CoworkingBilling";

export default function Router() {
    const {token} = useMyContext();
    const isClient = localStorage.getItem('userRole') === 'client';
    const isAdmin = localStorage.getItem('userRole') === 'admin';
    const isLoggedIn = token !== undefined;

    const redirectToHomeIfNotAdmin = (element) => isAdmin ? element : <Navigate to="/"/>;
    const redirectToHomeIfNotLoggedIn = (element) => isLoggedIn ? element : <Navigate to="/"/>;

    return useRoutes([
        {
            path: "/",
            element: <DashboardLayout/>,
            children: [
                {path: "", element: <Products/>},
                {path: "managephotos", element: redirectToHomeIfNotAdmin(<PhotosManager/>)},
                {path: "manageespaces", element: redirectToHomeIfNotAdmin(<ManageEspaces/>)},
                {path: "managedemandes", element: redirectToHomeIfNotAdmin(<ManageDemandes/>)},
                {path: "managetypesespaces", element: redirectToHomeIfNotAdmin(<ManageTypesEspaces/>)},
                {path: "manageservices", element: redirectToHomeIfNotAdmin(<ManageServices/>)},
                {path: "manageroles", element: redirectToHomeIfNotAdmin(<ManageRoles/>)},
                {path: "manageavantages", element: redirectToHomeIfNotAdmin(<ManageAvantages/>)},
                {path: "users", element: isClient ? <Navigate to="/"/> : <ManageUsers/>},
                {path: "usersroles", element: isClient ? <Navigate to="/"/> : <ManageUsersRoles/>},
                {path: "login", element: <Login/>},
                {path: "register", element: <Register/>},
                {path: "logout", element: <Logout/>},
                {path: "profileForm", element: <ProfileForm/>},
                {path: "espaceForm/:espaceId", element: redirectToHomeIfNotAdmin(<EspaceForm/>)},
                {path: "equipementForm/:equipementId", element: redirectToHomeIfNotAdmin(<EquipementForm/>)},
                {path: "salle/:salleId", element: <SalleDetails/>},
                {path: "reservation", element: <ReservationSummary/>},
                {path: "manageequipements", element: <ListeEquipements/>},
                {path: "devis", element: redirectToHomeIfNotAdmin(<ReservationForm salle={5}/>)},
                {path: "form/:itemsName/:itemId", element: redirectToHomeIfNotAdmin(<GenericModifyForm/>)},
                {path: "addImages/:salleId", element: <UploadImages/>},
                {path: "*", element: <NotFound/>},
                {path: "evenements", element: <Event/>},
                {path: "cgv", element: <CGV/>},
                {path: "forgot-password", element: <ForgotPassword/>}, 
                {path: "reset-password/:token", element: <ResetPassword/>},
                {path: "coworkingbilling/:salleId", element: <CoworkingBilling/>},
            ],
        },
        {
            path: "account",
            element: redirectToHomeIfNotLoggedIn(<SidebarAccount/>),
            children: [
                {path: "informations", element: <Informations/>},
                {path: "mot-de-passe", element: <ChangePassword/>},
                {path: "factures", element: <Factures/>},
            ],
        },
    ]);
}
