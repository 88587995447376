import React from "react";
import {Outlet} from "react-router-dom";
// material
import {styled} from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import Footer from "./Footer";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const MainStyle = styled("div")(({theme}) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "80vh",
    width: "80%",
    margin: "auto",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
    return (
        <div className="dashboard-wrap">
            <DashboardNavbar/>
            <MainStyle className="dashboard-inner-wrap">
                <Outlet/>
            </MainStyle>
            <Footer/>
        </div>

    );
};
export default DashboardLayout;
