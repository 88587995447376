import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import {useMyContext} from "../../../MyContext";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from "prop-types";

export default function LoginForm(props) {
    const {
        setIsUserLoggedIn = () => {},
        token,
        setToken,
        setUserRole,
        setUserInfos,
    } = useMyContext();
  const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await axios.post(
              `${apiUrl}/utilisateur/connexion`,
              values
          );

          // Utiliser le context pour sauvegarder les informations également
          setUserRole(response.data.role);
          setToken(response.data.token);
          setUserInfos(response.data.utilisateur);
          // Rediriger si nécessaire
          props.redirectToHome && navigate("/", {replace: true});
          // Notifier la connexion
          setIsUserLoggedIn(!token);
      } catch (error) {
          if (error.response) {
              // La requête a été faite et le serveur a répondu avec un code d'état autre que 2xx.
              alert(`Erreur : ${error.response.data.message}`);
          } else if (error.request) {
              // La requête a été faite, mais aucune réponse n'a été reçue.
              alert("Aucune réponse du serveur");
          } else {
              // Une erreur s'est produite lors de la configuration de la requête.
              alert("Une erreur s'est produite");
          }
      }
    },
  });


  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Adresse email"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle/>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"} // Affiche ou masque le mot de passe
                        label="Mot de passe"
                        {...getFieldProps("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{my: 2}}
                >

                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Connexion
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}

LoginForm.propTypes = {
    redirectToHome: PropTypes.bool,
};
