import {Form, FormikProvider, useFormik} from "formik";
import {Stack, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {useMyContext} from "../MyContext";

// ----------------------------------------------------------------------

export default function AddEquipementForm() {
    const {token} = useMyContext();

    const formik = useFormik({
        initialValues: {
            nom: "",
            prix: "",
        },
        onSubmit: async (values) => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;

                const requestOptions = {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                };
                const response = await axios.post(
                    `${apiUrl}/equipements`, // Ne surtout pas laisser un '/' à la fin sous peine d'erreur CORS
                    values,
                    requestOptions
                );
                //Recharger la page*/
                window.location.reload();
            } catch (error) {
                console.error(error);
                alert("Une erreur est survenue lors de l'ajout de l'équipement");
                console.error(values);
            }
        },
    });
    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} =
        formik;

    return (
        <div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <h1>Ajoutez un équipement </h1>
                    <br></br>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            autoComplete="nom"
                            type="text"
                            label="Nom"
                            {...getFieldProps("nom")}
                            error={Boolean(touched.nom && errors.nom)}
                            helperText={touched.nom && errors.nom}
                        />

                        <TextField
                            fullWidth
                            autoComplete="prix_m2"
                            type="number"
                            label="Prix"
                            {...getFieldProps("prix")}
                            error={Boolean(touched.prix && errors.prix)}
                            helperText={touched.prix && errors.prix}
                        />

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            id="register_button"
                        >
                            Ajouter
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </div>
    );
}
