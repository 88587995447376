import React, { useState } from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import EspacesToManage from "../components/EspacesToManage";
import AddEspaceForm from "../components/AddEspaceForm";

export default function ManageSpaces() {
    const [showForm, setShowForm] = useState(false);

    const handleToggle = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">
                    Gestion des Espaces
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggle}
                >
                    {showForm ? "Liste des salles" : "Ajouter une salle"}
                </Button>
            </Box>

            {showForm ? <AddEspaceForm /> : <EspacesToManage />}
        </Container>
    );
}
