import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { MyContextProvider } from "./MyContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

//
import App from "./App";
import "./App.css";

// ----------------------------------------------------------------------

function AppWithCallbackAfterRender() {
  return (
    <HelmetProvider>
      <BrowserRouter>
      <MyContextProvider>
        <App />
      </MyContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
