import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import {useMyContext} from "../MyContext";

const ImageGallery = () => {
    const [salle, setSalle] = useState([]);
    const [images, setImages] = useState([]);
    // Récupérer le paramètre "salleId" depuis l'URL grâce au hook useParams de React Router
    const {salleId} = useParams();
    const {token} = useMyContext();

    const confirmDelete = (imageId, salleId) => {
        // Logique de suppression ou affichage de la boîte de dialogue de confirmation
        const apiUrl = process.env.REACT_APP_API_URL;


        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
            axios.delete(`${apiUrl}/images/espace/${salleId}/${imageId}`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
                .then(response => {
                    //console.log('Image supprimée avec succès !');
                    // Mettre à jour les images après la suppression
                    const updatedImages = images.filter(image => image.id !== imageId);
                    setImages(updatedImages);
                })
                .catch(error => {
                    console.error('Erreur lors de la suppression de l\'image :', error);
                });
        }
    };

    const setAsMain = (imageId, salleId) => {
        const apiUrl = process.env.REACT_APP_API_URL;

        axios.post(`${apiUrl}/setmainimage/espace`, // Ne surtout pas laisser un '/' à la fin sous peine d'erreur CORS
            {
                image_id: imageId,
                espace_id: salleId
            }, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then(response => {
                alert('Les modifications ont bien été apportés');
                window.location.reload();
            })
            .catch(error => {
                console.error('Erreur lors de la suppression de l\'image :', error);
            });
    }

    // Utiliser useEffect pour charger les détails de la salle dès que le composant est monté
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const getImagesOfRoom = async () => {
            try {
                const response = await axios.get(`${apiUrl}/images/espace/${salleId}`);
                setImages(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        getImagesOfRoom();

        // Récupérer les infos de la salle
        const infosSalles = async () => {
            try {
                const response = await axios.get(`${apiUrl}/espaces/${salleId}`);
                setSalle(response.data.salle);
            } catch (error) {
                console.error(error);
            }
        };
        infosSalles();
    }, [salleId]);

    return (
        <div className="all-images">
            {images.map((image) => (
                <div key={image.id} className="image-wrapper">
                    <div className="img-container">
                        <div className="content">
                            <div className="content-overlay"></div>
                            <img className="content-image" src={image.url} alt=""/>
                            <div className="content-details fadeIn-bottom">
                                <button type="button" className="btn btn-primary"
                                        disabled={salle && image.url === salle.image_principale}
                                        onClick={() => setAsMain(image.id, salleId)}>
                                    Principale
                                </button>
                                <button className="btn btn-danger" onClick={() => confirmDelete(image.id, salleId)}>
                                    Supprimer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImageGallery;
