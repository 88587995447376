import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import {useNavigate} from "react-router-dom";
// material
import {Alert, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// component
import axios from "axios";
import {useMyContext} from "../../../MyContext";
import PropTypes from "prop-types";
import {useState} from "react";


// ----------------------------------------------------------------------

export default function RegisterForm(props) {
    const {
        setIsUserLoggedIn,
        token,
        setToken,
        setUserRole,
        setUserInfos,
    } = useMyContext();
    const navigate = useNavigate();

    // Affichage du message d'erreur
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const RegisterSchema = Yup.object().shape({
        prenom: Yup.string()
            .min(1, "Trop court")
            .max(50, "Trop long!")
            .required("Veuillez renseigner votre prénom"),
        nom: Yup.string()
            .min(1, "Trop court")
            .max(50, "Trop long!")
            .required("Veuillez renseigner votre nom"),
        email: Yup.string()
            .email("Cet email n'est pas valide")
            .required("L'email est requis"),
        password: Yup.string().required("Le mot de passe est obligatoire").min(8, "Le mot de passe doit contenir au moins 8 caractères"),
        confirmPassword: Yup.string().required("Veuillez confirmer votre mot de passe").test('passwords-match', 'Les mots de passe ne correspondent pas', function (value) {
            return this.parent.password === value;
        }),
        adresse: Yup.string().required("Veuillez renseigner votre Adresse"),
        codepostale: Yup.number()
            .required('Le code postal est requis'),
        ville: Yup.string()
            .required('Veuillez renseigner votre ville'),
        tel: Yup.number(),
        siret: Yup.number(),
        entreprise: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            prenom: "",
            nom: "",
            email: "",
            password: "",
            confirmPassword: "",
            adresse: "",
            codepostale: "",
            ville: "",
            tel: "",
            siret: "",
            entreprise: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(
                    `${apiUrl}/utilisateur/inscription`,
                    values
                );
                // Utiliser le context pour sauvegarder les informations également
                setToken(response.data.token);
                setUserRole(response.data.role);
                setUserInfos(response.data.utilisateur);
                // Notifier la connexion
                setIsUserLoggedIn(!token);

                // Rediriger si nécessaire
                props.redirectToHome && navigate("/", {replace: true});
            } catch (error) {
                // Si le code est 422, l'email est déjà utilisé
                if (error.response.status === 422) {
                    setError(true);
                    setErrorMessage("L'adresse email est déjà utilisée.");
                } else if (error.response) {
                    // La requête a été faite et le serveur a répondu avec un code d'état autre que 2xx.
                    alert(`Erreur : ${error.response.data.message}`);
                } else if (error.request) {
                    // La requête a été faite, mais aucune réponse n'a été reçue.
                    alert("Aucune réponse du serveur");
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête.
                    alert("Une erreur s'est produite");
                }
                console.error(error);
            }
        },
    });
    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} =
        formik;

    return (
        <FormikProvider value={formik}>
            {/*Affichage du message d'erreur*/}
            {error && <Alert className={"errorMessage"} variant="filled" severity="error">{errorMessage}</Alert>}
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Stack direction={{xs: "column", sm: "row"}} spacing={2}>
                        <TextField
                            fullWidth
                            label="Prénom"
                            {...getFieldProps("prenom")}
                            error={Boolean(touched.prenom && errors.prenom)}
                            helperText={touched.prenom && errors.prenom}
                        />

                        <TextField
                            fullWidth
                            label="Nom"
                            {...getFieldProps("nom")}
                            error={Boolean(touched.nom && errors.nom)}
                            helperText={touched.nom && errors.nom}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        type="email"
                        label="Adresse email"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                                        <TextField
                        fullWidth
                        type={"password"}
                        label="Mot de passe"
                        {...getFieldProps("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    <TextField
                        fullWidth
                        type={"password"}
                        label="Confirmer mot de passe"
                        {...getFieldProps("confirmPassword")}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                    />
                    <Typography variant="h6" gutterBottom>
                        Informations de facturation
                    </Typography>

                    <TextField
                        fullWidth
                        label="Entreprise (facultatif) *"
                        {...getFieldProps("entreprise")}
                        error={Boolean(touched.entreprise && errors.entreprise)}
                        helperText={touched.entreprise && errors.entreprise}
                    />
                    <TextField
                        fullWidth
                        label="SIRET (facultatif) *"
                        {...getFieldProps("siret")}
                        error={Boolean(touched.siret && errors.siret)}
                        helperText={touched.siret && errors.siret}
                    />
                    <Typography variant="caption" color="textSecondary">
                        * Si ces champs sont remplis, la facture sera par défaut au nom de l'entreprise.
                    </Typography>
                    <TextField
                        fullWidth
                        label="Adresse"
                        {...getFieldProps("adresse")}
                        error={Boolean(touched.adresse && errors.adresse)}
                        helperText={touched.adresse && errors.adresse}
                    />
                    <Stack direction={{xs: "column", sm: "row"}} spacing={2}>

                        <TextField
                            fullWidth
                            label="Code Postal"
                            {...getFieldProps("codepostale")}
                            error={Boolean(touched.codepostale && errors.codepostale)}
                            helperText={touched.codepostale && errors.codepostale}
                        />

                        <TextField
                            fullWidth
                            label="Ville"
                            {...getFieldProps("ville")}
                            error={Boolean(touched.ville && errors.ville)}
                            helperText={touched.ville && errors.ville}
                        />
                    </Stack>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        S'inscrire
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

RegisterForm.propTypes = {
    redirectToHome: PropTypes.bool,
};

