import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Button, Card, CardMedia, Grid, TextField } from '@mui/material';
import ImageGallery from "./ImageGallery";
import {useMyContext} from "../MyContext";

export default function UploadImages() {
    const navigate = useNavigate();
    const {token} =  useMyContext();
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]); // Nouveau state pour les aperçus
    const [error, setError] = useState(null);

    const handleFileChange = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);

        // Générer des aperçus pour chaque image sélectionnée
        const previews = selectedImages.map((image) => URL.createObjectURL(image));
        setImagePreviews(previews);
    };

    const goToRoomPage= () => {
        navigate("/salle/" + salleId);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const formDataToSend = new FormData();

            images.forEach((image, index) => {
                formDataToSend.append(`image${index + 1}`, image);
            });

            const response = await axios.post(
                `${apiUrl}/images/espace/` + salleId,
                formDataToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            // Recharger la page
            window.location.reload()

        } catch (error) {
            console.error(error);
            setError(
                "Nous ne parvenons pas à stocker vos images, veuillez contacter l'équipe technique si l'erreur persiste : technique@agencedesmonstres.com"
            );
        }
    };

    // Récupérer le paramètre "salleId" depuis l'URL grâce au hook useParams de React Router
    const { salleId } = useParams();


    return (
        <div>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Typography variant="h4">Ajoutez des images à la salle</Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <input
                            type="file"
                            id="images"
                            name="images"
                            multiple
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="images">
                            <Button variant="outlined" component="span">
                                Choisissez des images
                            </Button>
                        </label>
                        {error && <Typography color="error">{error}</Typography>}
                    </Grid>
                    {imagePreviews.map((preview, index) => (
                        <Grid item xs={3} key={index}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="100"
                                    image={preview}
                                    alt={`Preview ${index + 1}`}
                                />
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" disabled={!images.length}>
                            Envoyer
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ImageGallery/>
            <button type="button" onClick={goToRoomPage} className="btn btn-success">Terminer</button>
        </div>
    );
}
