import React, { useContext, useEffect, useState } from "react";
import Calendar from "@demark-pro/react-booking-calendar";
import { MyContext } from "../../MyContext";
import { Box, Button, Container, Grid, Paper } from "@mui/material";
import { addHours, format, isAfter, isBefore, startOfDay } from "date-fns";
import frLocale from "date-fns/locale/fr";
import "../../style/calendars/calendarHour.css";
import PropTypes from "prop-types";
import PointsTurning from "../loading/PointsTurning";

export default function CalendarHour({ events }) {
  const {
    setSelectedDate,
    selectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    setSelectedHourCount,
    setSelectedDay,
    setEarliestHour,
    setLatestHour,
    setSelectedTimeRanges,
    selectedTimeRanges,
    type,
  } = useContext(MyContext);

  const [calendarEvents, setCalendarEvents] = useState({ events });
  const [isLoading, setIsLoading] = useState(true);
  const [delayedRender, setDelayedRender] = useState(false);

  useEffect(() => {
    if (selectedDate && calendarEvents) {
        setIsLoading(false);

        // Introduire un délai pour permettre à la logique de désactivation de se terminer
        setTimeout(() => {
            setDelayedRender(true);
        }, 500); // Délai de 100ms, ajustable selon tes besoins
    } else {
        setIsLoading(true);
        setDelayedRender(false);
    }
}, [selectedDate, calendarEvents]);


  useEffect(() => {
    if (selectedDate) {
      setEarliestHour(getEarliestHour());
      setLatestHour(getLatestHour());
    }
  }, [selectedDate, selectedTimeRanges]);

  useEffect(() => {
    // Simule le chargement des dépendances
    if (selectedDate && calendarEvents) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [selectedDate, calendarEvents]);

  const handleChange = (e) => {
    setSelectedDate(e[0]);
    setSelectedTimeRanges([]);
    setSelectedStartTime("");
    setSelectedEndTime("");
    setSelectedHourCount(0);
    setSelectedDay(null);
  };

  const getStartHourForSlot = (index) => {
    const baseDate = selectedDate;
    const startHour = new Date(baseDate);
    startHour.setHours(9 + index);
    return startHour;
  };

  const handleTimeSlotClick = (timeRange) => {
    const newSelectedRanges = [...selectedTimeRanges];
    const index = selectedTimeRanges.indexOf(timeRange);

    if (index !== -1) {
      if (index === newSelectedRanges.length - 1 || index === 0) {
        newSelectedRanges.splice(index, 1);
      } else {
        newSelectedRanges.splice(index, newSelectedRanges.length - index);
      }
    } else {
      newSelectedRanges.push(timeRange);
      newSelectedRanges.sort();
      for (let i = 0; i < newSelectedRanges.length - 1; i++) {
        const startHour = parseInt(newSelectedRanges[i].split("-")[0]);
        const endHour = parseInt(
          newSelectedRanges[i + 1].split("-")[1].split(":")[0]
        );

        for (let hour = startHour + 1; hour < endHour; hour++) {
          const firstHour = hour < 10 ? `0${hour}` : hour;
          const secondHour = hour + 1 < 10 ? `0${hour + 1}` : hour + 1;
          const newTimeRange = `${firstHour}:00 - ${secondHour}:00`;
          if (!newSelectedRanges.includes(newTimeRange)) {
            newSelectedRanges.splice(i + 1, 0, newTimeRange);
            i++;
          }
        }
      }
    }

    setSelectedTimeRanges(newSelectedRanges);
    setSelectedStartTime(newSelectedRanges[0]);
    setSelectedEndTime(newSelectedRanges[newSelectedRanges.length - 1]);
    setSelectedHourCount(newSelectedRanges.length);
    setSelectedDay(selectedDate);
  };

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0;
  };

  function isWeekday(date) {
    const day = date.getDay();
    return day >= 1 && day <= 5;
  }

  const getEarliestHour = () => {
    if (selectedTimeRanges.length > 0) {
      const startHours = selectedTimeRanges.map((range) =>
        parseInt(range.split("-")[0])
      );
      return Math.min(...startHours);
    }
    return "";
  };

  const getLatestHour = () => {
    if (selectedTimeRanges.length > 0) {
      const endHours = selectedTimeRanges.map((range) =>
        parseInt(range.split("-")[1].split(":")[0])
      );
      return Math.max(...endHours);
    }
    return "";
  };

  let eventsCalendar = events && events.events ? events.events : [];

  const eventDetails = eventsCalendar.flatMap((event) => {
    const startDateTime = new Date(event.googleEvent.start.dateTime);
    const endDateTime = new Date(event.googleEvent.end.dateTime);

    const formattedStartDate = startDateTime.toLocaleDateString("fr-FR");
    const formattedStartTime = startDateTime.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const formattedEndDate = endDateTime.toLocaleDateString("fr-FR");
    const formattedEndTime = endDateTime.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const timeRanges = [];
    let currentHour = startDateTime.getHours();

    while (currentHour < endDateTime.getHours()) {
      const timeRange = `${currentHour}:00 - ${currentHour + 1}:00`;
      timeRanges.push(
        `${formattedStartDate} - ${formattedStartTime} à ${formattedEndTime}`
      );
      currentHour++;
    }

    return timeRanges;
  });

  const [translate, setTranslate] = useState(false);
  useEffect(() => {
    const containers = document.querySelectorAll(".calendar-hour-container");
    containers.forEach((container) => {
      const span = container.querySelector("span");
      if (span && span.textContent) {
        const englishMonth = span.textContent.trim();
        span.textContent = getFrenchMonth(englishMonth);
      }
    });
  }, [translate]);

  const getFrenchMonth = (englishMonth) => {
    const monthsMap = {
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
    };
    return monthsMap[englishMonth] || englishMonth;
  };

  if (isLoading) {
    return <PointsTurning />;
  }

  return (
    <Container maxWidth="md" className={"calendar-hour-container"}>
      <Box mt={4}>
        <Grid container spacing={2} className="calendar-wrapper">
          <Grid item xs={8} className="calendar-content">
            <Paper elevation={3} sx={{ padding: 2 }}>
              {delayedRender && (
                <Calendar
                  selected={[selectedDate]}
                  onChange={handleChange}
                  onMonthChange={() => setTranslate(!translate)}
                  onOverbook={(e, err) =>
                    alert("Impossible de réserver cette date")
                  }
                  disabled={(date, state) => {
                    const dateString = date.toISOString().split("T")[0];
                    if (isBefore(date, yesterday)) {
                      return true;
                    }
                    if (type.reservable_par_poste) {
                      return false;
                    }
                    if (
                      calendarEvents &&
                      calendarEvents.events &&
                      Array.isArray(calendarEvents.events.events) &&
                      calendarEvents.events.events.length > 0
                    ) {
                      const isEventDate = calendarEvents.events.events.some(
                        (event) => {
                          const eventStartDate = new Date(
                            event.googleEvent.start.date
                          );
                          const eventEndDate = new Date(
                            event.googleEvent.end.date
                          );

                          eventStartDate.setDate(eventStartDate.getDate() - 1);
                          eventEndDate.setDate(eventEndDate.getDate() - 1);

                          return (
                            isBefore(date, eventEndDate) &&
                            isAfter(date, eventStartDate)
                          );
                        }
                      );
                      if (isEventDate) {
                        return true;
                      }
                    }
                    return false;
                  }}
                  events={eventDetails}
                  variant="events"
                  dateFnsOptions={{ weekStartsOn: 1, locale: frLocale }}
                  range={false}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={4} className="time-slots">
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Box display="flex" flexDirection="column">
                {Array.from({ length: 12 }, (_, index) => {
                  const startTime = addHours(
                    startOfDay(selectedDate),
                    8 + index
                  );
                  const endTime = addHours(startOfDay(selectedDate), index + 9);
                  const timeRange = `${format(startTime, "HH:mm")} - ${format(
                    endTime,
                    "HH:mm"
                  )}`;
                  const isSelected = selectedTimeRanges.includes(timeRange);
                  const isDisabled = type.reservable_par_poste
                    ? false
                    : eventDetails.some((event) => {
                        const isDayReserved = (date) => {
                          return events.some((event) => {
                            const eventStartDate = new Date(
                              event.googleEvent.start.dateTime
                            );
                            return (
                              format(eventStartDate, "yyyy-MM-dd") ===
                              format(date, "yyyy-MM-dd")
                            );
                          });
                        };

                        const [eventStart, eventEnd] = event.split(" à ");
                        const [eventDate, eventStartTime] =
                          eventStart.split(" - ");
                        const [eventEndTime] = eventEnd.split(" - ");

                        const startTime = format(
                          addHours(startOfDay(selectedDate), 8 + index),
                          "HH:mm"
                        );
                        const endTime = format(
                          addHours(startOfDay(selectedDate), index + 9),
                          "HH:mm"
                        );
                        return (
                          eventDate === format(selectedDate, "dd/MM/yyyy") &&
                          ((eventStartTime <= startTime &&
                            startTime < eventEndTime) ||
                            (eventStartTime < endTime &&
                              endTime <= eventEndTime))
                        );
                      });

                  if (isBefore(startTime, new Date())) {
                    return null;
                  }

                  return (
                    <Button
                      key={index}
                      variant={isSelected ? "contained" : "outlined"}
                      sx={{ mb: 1 }}
                      onClick={() => handleTimeSlotClick(timeRange)}
                      disabled={isDisabled}
                      className="heures"
                    >
                      {`${format(startTime, "HH:mm")} - ${format(
                        endTime,
                        "HH:mm"
                      )}`}
                    </Button>
                  );
                })}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

CalendarHour.propTypes = {
  events: PropTypes.object,
};
