import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {useMyContext} from "../../../MyContext";

export default function EquipementForm(){
  const {token} = useMyContext();
  const [isLoading, setIsLoading] = useState(false);
  const { equipementId } = useParams(); // Récupère l'ID passé dans l'URL
  const navigate = useNavigate();
  const [equipement, setEquipement] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setIsLoading(true); // Définir isLoading à true au début de la requête

    try {
      const response = axios.get(
        `${apiUrl}/equipements/${equipementId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      response.then((res) => {
        setEquipement(res.data);
        setIsLoading(false); // Définir isLoading à false lorsque la requête est terminée
        setDataLoaded(true); // Indiquer que les données sont chargées
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false); // Définir isLoading à false en cas d'erreur
      throw error;
    }
  }, [equipementId, token]);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      const updateResponse = await axios.put(
        `${apiUrl}/equipements/${equipementId}`,
        {
          nom: values.nomEquipement,
          prix: values.prix,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log(updateResponse);
      navigate("/manageequipements", { replace: true });
    }
    catch (error) {
          console.log(values);
          console.log(error);
          throw error;
    }
    setIsLoading(false);
  };

    if (!dataLoaded) {
      return <div>Loading...</div>; // Ou affichez un spinner ou un autre indicateur de chargement
    }

  return (
    <div className="container">
      <h2>Modifier l'équipement</h2>
      <Formik
        initialValues={{
          nomEquipement: equipement ? equipement.data.nom : "",
          prix: equipement ? equipement.data.prix : "",
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="nomEquipement" className="form-label">
                Nom de l'équipement
              </label>
              <Field
                type="text"
                id="nomEquipement"
                name="nomEquipement"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="prix" className="form-label">
                Prix
              </label>
              <Field
                type="text"
                id="prix"
                name="prix"
                className="form-control"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Modifier"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
