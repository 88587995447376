import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box
} from "@mui/material";
import EspaceMoreMenu from "./button/EspaceMoreMenu";
import axios from "axios";

export default function EspacesToManage() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortCriteria, setSortCriteria] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [espacesResponse, typesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/espaces`),
                    axios.get(`${apiUrl}/typesespaces`)
                ]);
                setData(espacesResponse.data);
                setTypes(typesResponse.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortCriteria(event.target.value);
    };

    const sortData = (data) => {
        switch (sortCriteria) {
            case "nom":
                return [...data].sort((a, b) => a.nom.localeCompare(b.nom));
            case "type":
                return [...data].sort((a, b) => {
                    const typeA = types.find(type => type.id === parseInt(a.type_id, 10))?.nom || "";
                    const typeB = types.find(type => type.id === parseInt(b.type_id, 10))?.nom || "";
                    return typeA.localeCompare(typeB);
                });
            case "prix_heure":
                return [...data].sort((a, b) => a.prix_heure - b.prix_heure);
            case "prix_journee":
                return [...data].sort((a, b) => a.prix_journee - b.prix_journee);
            case "prix_mois":
                return [...data].sort((a, b) => a.prix_mois - b.prix_mois);
            default:
                return data;
        }
    };

    const filteredData = sortData(
        data.filter((item) =>
            item.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            types.find(type => type.id === parseInt(item.type_id, 10))?.nom.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    if (loading) {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container sx={{ mt: 4 }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Liste de salles
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                        fullWidth
                        label="Rechercher un espace"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Trier par</InputLabel>
                        <Select
                            value={sortCriteria}
                            label="Trie par"
                            onChange={handleSortChange}
                        >
                            <MenuItem value="">Aucun</MenuItem>
                            <MenuItem value="nom">Nom</MenuItem>
                            <MenuItem value="type">Type</MenuItem>
                            <MenuItem value="prix_heure">Prix par heure</MenuItem>
                            <MenuItem value="prix_journee">Prix par jour</MenuItem>
                            <MenuItem value="prix_mois">Prix par mois</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Prix par heure</TableCell>
                            <TableCell>Prix par jour</TableCell>
                            <TableCell>Prix par mois</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Link to={`/salle/${item.id}`}>{item.nom}</Link>
                                </TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>
                                    {types.find((type) => type.id === parseInt(item.type_id, 10))?.nom || item.type_id}
                                </TableCell>
                                <TableCell>{item.prix_heure}</TableCell>
                                <TableCell>{item.prix_journee}</TableCell>
                                <TableCell>{item.prix_mois}</TableCell>
                                <TableCell>
                                    <EspaceMoreMenu espaceId={item.id} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
}
