import GenericList from "../components/GenericList";
import GenericAddForm from "../components/forms/add/GenericAddForm";

export default function ManageManagersEmail(props) {
    const keysToExclude = ["id", "created_at", "updated_at" ];

    return (
        <div className={"manage-managers-email-wrapper"}>
            <h1>Adresses emails recevant les messages de demandes de devis</h1>
            <GenericList item={"managermails"} keysToExclude={keysToExclude}/>
            <h1>Ajouter une nouvelle adresse</h1>
            <GenericAddForm item={"managermails"} />
        </div>
    )
}