import GenericList from "../components/GenericList";

export default function ManageUsers() {
    // Préciser quelles sont les colonnes à exclure de l'affichage
    const keysToExclude = ["id", "created_at", "updated_at", "siret", "reseaux_sociaux_id","entreprise_id", "actif",
        "role_id", "url_signature", "email_verified_at", "type_evoliz", "remember_token", "url_photo","expertise", "description", "password", "email_verified_at", "remember_token", "created_at", "updated_at", "role_id", "entreprise_id", "siret", "reseaux_sociaux_id", "url_photo", "url_signature", "type_evoliz", "expertise", "description"]

    return (
        <div>
            <h1>Utilisateurs</h1>
            <GenericList item={"users"} keysToExclude={keysToExclude} />
        </div>
    );
}