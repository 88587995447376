import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Formik, Field, Form, ErrorMessage, FieldArray} from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import {useMyContext} from "../MyContext";

const ManageUsersRoles = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const {token} = useMyContext();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Faites votre requête GET pour récupérer les données des utilisateurs ici
        axios.get(`${apiUrl}/roles_users`, {headers: {Authorization: "Bearer " + token}})
            .then(response => setUsers(response.data))
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs :', error));
    }, []);

    return (
        <Formik
            initialValues={{users}}
            onSubmit={(values) => {
                // Logique de soumission du formulaire
                //console.log('Formulaire soumis avec les valeurs :', values);
            }}
        >
            <Form>
                <FieldArray name="users">
                    {({push, remove}) => (
                        <>
                            {users.map((user, index) => (
                                <div key={index}>
                                    <h4>{`${user.prenom} ${user.nom}`}</h4>
                                    <Autocomplete
                                        multiple
                                        id={`roles-${index}`}
                                        options={user.roles}
                                        getOptionLabel={(option) => option.nom}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, tagIndex) => (
                                                <Chip
                                                    key={tagIndex}
                                                    label={option.nom}
                                                    {...getTagProps({index: tagIndex})}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Rôles"
                                            />
                                        )}
                                    />
                                    <button type="button" onClick={() => remove(index)}>
                                        Supprimer cet utilisateur
                                    </button>
                                </div>
                            ))}
                        </>
                    )}
                </FieldArray>

                <button type="submit">
                    Enregistrer les modifications
                </button>
            </Form>
        </Formik>
    );
};

export default ManageUsersRoles;
