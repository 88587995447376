import React, {useEffect} from 'react';
import {useMyContext} from '../../MyContext';
import "../../style/calendars/calendarMonth.css";


function CalendarMonth() {

    const {
        selectedMonths,
        setSelectedMonths,
        selectedStartMonth,
        setSelectedStartMonth,
        selectedEndMonth,
        setSelectedEndMonth,
        startIndex,
        setStartIndex,
        endIndex,
        setEndIndex,
        startYearValue,
        setStartYearValue,
        endYearValue,
        setEndYearValue,
    } = useMyContext();

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];

    const generateMonthsOptions = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        const options = [];
        for (let i = 0; i < 12; i++) {
            const year = currentMonth + i > 11 ? currentYear + 1 : currentYear;
            const monthIndex = (currentMonth + i) % 12;
            const monthName = months[monthIndex];
            options.push(`${monthName} ${year}`);
        }

        return options;
    };

    useEffect(() => {
        calculateMonthDifference();
    }, [selectedStartMonth, selectedEndMonth]);

    const handleStartMonthChange = (event) => {
        setSelectedStartMonth(event.target.value);
    };

    const handleEndMonthChange = (event) => {
        setSelectedEndMonth(event.target.value);
    };

    const calculateMonthDifference = () => {
        if (selectedStartMonth && selectedEndMonth) {
            const [startMonthName, startYear] = selectedStartMonth.split(" ");
            const [endMonthName, endYear] = selectedEndMonth.split(" ");
            const startIndex = months.indexOf(startMonthName);
            const endIndex = months.indexOf(endMonthName);

            // Mettez à jour les valeurs dans le contexte
            setStartIndex(startIndex);
            setEndIndex(endIndex);

            if (startIndex !== -1 && endIndex !== -1) {
                const currentYear = new Date().getFullYear();
                const startYearValue = parseInt(startYear);
                const endYearValue = parseInt(endYear);

                // Mettez à jour les valeurs dans le contexte
                setStartYearValue(startYearValue);
                setEndYearValue(endYearValue);

                const monthsInStartYear = 12 - startIndex;
                const monthsInEndYear = endIndex + 1;
                const fullYearsBetween = endYearValue - startYearValue - 1;
                const monthsSelected =
                    monthsInStartYear + fullYearsBetween * 12 + monthsInEndYear;

                setSelectedMonths(monthsSelected);
            }
        }
    };


    return (
        <div className="calendar-month-container">
            <div className="calendar-select">
                <label htmlFor="start-month">De</label>
                <select
                    id="start-month"
                    value={selectedStartMonth}
                    onChange={handleStartMonthChange}
                >
                    <option value="">Sélectionnez un mois</option>
                    {generateMonthsOptions().map((monthOption) => (
                        <option key={monthOption} value={monthOption}>
                            {monthOption}
                        </option>
                    ))}
                </select>
            </div>
            <div className="calendar-select">
                <label htmlFor="end-month">À</label>

                <select id="end-month" value={selectedEndMonth} onChange={handleEndMonthChange}>
                    <option value="">Sélectionnez un mois</option>
                    {generateMonthsOptions().map((monthOption) => (
                        <option key={monthOption} value={monthOption}>
                            {monthOption}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default CalendarMonth;
