import {useState} from "react";
import {Button, Grid, Paper, TextField, Typography} from "@mui/material";
import {Alert} from "@mui/material";
import axios from "axios";

export default function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [notGood, setNotGood] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            "old-password" : currentPassword,
            "new-password" : newPassword
        }
        // Vérification de la validité des champs et soumission du formulaire
        if (newPassword === confirmNewPassword) {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/update-password`, data, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
                    }
                }).then((response) => {
                    setSuccess(true);
                    setError(false);
                    setNotGood(false);
                    // Effacer les champs
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmNewPassword("");
                }).catch((error) => {
                    setNotGood(true);
                    setSuccess(false);
                    setError(false);
                });
        }
        else {
            setError(true);
            setSuccess(false);
            setNotGood(false);
        }
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
                <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
                    <Typography variant="h5" gutterBottom>
                        Modifier le mot de passe
                    </Typography>
                    {success && (
                    <Alert severity="success">Votre mot de passe a été modifié avec succès !</Alert>
                    )}
                    {error &&
                    <Alert severity="error">Le nouveau mot de passe ne correspond pas à sa confirmation.</Alert>
                    }
                    {notGood &&
                    <Alert severity="error">Le mot de passe actuel n'est pas correct.</Alert>
                    }
                    <form onSubmit={handleSubmit}>
                        <TextField
                            type="password"
                            label="Mot de passe actuel"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <TextField
                            type="password"
                            label="Nouveau mot de passe"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <TextField
                            type="password"
                            label="Confirmation du nouveau mot de passe"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 20 }}
                        >
                            Modifier le mot de passe
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
};