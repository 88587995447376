import React, {useState, useEffect} from "react";
import axios from "axios";
import "../style/addEquipements.css";
import {useMyContext} from "../MyContext";
import {Checkbox, FormControlLabel} from "@mui/material";
import {black} from "mui/source/styles/colors";
import {grey} from "@mui/material/colors";
import HorizontalSeparator from "./separators/HorizontalSeparator";

const AddEquipements = () => {
    const {
        equipements,
        prixSelectionnes,
        totalPrixEquipements,
        setEquipements,
        setPrixSelectionnes,
        setTotalPrixEquipements
    } = useMyContext(); // Utilisez useMyContext pour accéder au contexte


    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;

        const getEquipementsForSalle = async () => {
            try {
                const response = await axios.get(`${apiUrl}/equipements`);
                const {data} = response;
                setEquipements(data);
            } catch (error) {
                console.error(error);
            }
        };

        getEquipementsForSalle();
    }, []);

    const toggleCheckboxPrice = (equipement) => {
        const {prix} = equipement;

        setPrixSelectionnes((prevSelection) => {
            if (prevSelection.includes(equipement)) {
                // Décoche l'objet
                const updatedSelection = prevSelection.filter(
                    (selected) => selected !== equipement
                );
                updateTotal(updatedSelection);
                return updatedSelection;
            } else {
                // Coche l'objet
                const updatedSelection = [...prevSelection, equipement];
                updateTotal(updatedSelection);
                return updatedSelection;
            }
        });
    };

    const updateTotal = (selection) => {
        const newTotal = selection.reduce(
            (sum, equipement) => sum + equipement.prix,
            0
        );
        setTotalPrixEquipements(newTotal);
    };

    useEffect(() => {
        // Affiche la liste des objets cochés et leur prix ainsi que le total
        /*console.log("Objets cochés :", prixSelectionnes);
        console.log("Total :", totalPrixEquipements + "€");*/
    }, [prixSelectionnes, totalPrixEquipements]);

    return (
        <div className="add-equipements-container">
            <b className={"sous-titres"}>Sont en option : </b>
            <div className="equipements-list">
                {equipements ? (
                    equipements.map((equipement) => (
                        <div key={equipement.id} className="equipement-item">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`equipement-${equipement.id}`}
                                        onChange={() => toggleCheckboxPrice(equipement)}
                                        checked={prixSelectionnes.includes(equipement)}
                                        sx={{
                                            color: grey[900],
                                            '&.Mui-checked': {
                                                color: grey[900],
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <p className={"options-value"}>
                                        <span className={"nom-equipement"}>{equipement.nom}</span>
                                        <span className={"prix-equipement"}>{equipement.prix} €</span>
                                    </p>
                                }
                            />
                        </div>
                    ))
                ) : (
                    <p>Loading equipements...</p>
                )}
            </div>
        </div>
    );
};

export default AddEquipements;
