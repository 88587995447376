import {useFormik, Form, FormikProvider} from "formik";
import {TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import PropTypes from "prop-types";
import {useMyContext} from "../../MyContext";

export default function ReservationForm(props) {
    const {userInfos} = useMyContext();
    const userNom = userInfos.nom;
    const userPrenom = userInfos.prenom;

    const formik = useFormik({
        initialValues: {
            nom: "",
            prenom: "",
            email: "",
            salle: props.salle
        },
        onSubmit: async (values) => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(
                    `${apiUrl}/sendMail`,
                    {
                        values: values,
                    },
                );
            } catch (error) {
                console.error(error);
                alert("Une erreur est survenue lors de l'envoi de votre demande");
                console.error(values);
            }
        },
    });
    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} =
        formik;

    return (
        <div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <h1>Informations personelles</h1>
                    <br></br>
                    <TextField
                        fullWidth
                        type="text"
                        label="Nom"
                        autoComplete={userNom}
                        {...getFieldProps("nom")}
                        error={Boolean(touched.nom && errors.nom)}
                        helperText={touched.nom && errors.nom}
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Prenom"
                        autoComplete={userPrenom}
                        {...getFieldProps("prenom")}
                        error={Boolean(touched.prenom && errors.prenom)}
                        helperText={touched.prenom && errors.prenom}
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Email"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        id="register_button"
                    >
                        Demander un devis
                    </LoadingButton>
                </Form>
            </FormikProvider>
        </div>
    );
}

ReservationForm.propTypes = {
    salle: PropTypes.number.isRequired,
};
