import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useMyContext} from "../../../MyContext";

export default function GenericModifyForm() {
    const {token} = useMyContext();

    // Récupérer les infos des paramètres de l'URL
    const {itemsName, itemId} = useParams();

    const navigate = useNavigate();
    const [item, setItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        try {
            const response = axios.get(
                `${apiUrl}/${itemsName}/${itemId}`,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );
            response.then((res) => {
                setItem(res.data);
                setDataLoaded(true); // Indiquer que les données sont chargées
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, [itemId, token]);

    const handleSubmit = async (values) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir modifier cet élément ?");
        if (!confirmation) {
            return;
        }

        try {
            const updateResponse = await axios.put(
                `${apiUrl}/${itemsName}/${itemId}`,
                values,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );

            // Revenir en arrière
            navigate(-1);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    if (!dataLoaded) {
        return <div>Loading...</div>; // Ou affichez un spinner ou un autre indicateur de chargement
    }

    // Attributs à exclure
    const excludedKeys = ["id", "created_at", "updated_at", "role_id", "entreprise_id", "siret", "reseaux_sociaux_id", "url_photo", "url_signature", "type_evoliz", "expertise", "description", "password", "email_verified_at", "remember_token", "created_at", "updated_at", "role_id", "entreprise_id", "reseaux_sociaux_id", "url_photo", "url_signature", "type_evoliz", "expertise", "description", "evoliz_clientid"]

    const generateInitialValues = () => {
        if (!item) {
            return {};
        }

        return Object.keys(item).reduce((acc, key) => {
            if (!excludedKeys.includes(key)) {
                acc[key] = item[key];
            }
            return acc;
        }, {});
    };

    const initialValues = generateInitialValues();

    return (
        <div className="container">
            <h2>Modifier l'élément</h2>
            <p>
                <em>Note: Tous les champs commençant par "reservable" doivent être remplie avec <strong>0</strong> pour
                    non et <strong>1</strong> pour oui </em>
            </p>

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({isSubmitting}) => (
                    <Form>
                        {Object.keys(initialValues).map(key => (
                            <div key={key} className="mb-3">
                                <label htmlFor={key} className="form-label">
                                    {key}
                                </label>
                                <Field
                                    type="text"
                                    id={key}
                                    name={key}
                                    className="form-control"
                                />
                            </div>
                        ))}

                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            ) : (
                                "Modifier"
                            )}
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    );
}