import React, { useEffect, useState } from "react";
import "../style/eventList.css";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as PropTypes from "prop-types";
import PointsTurning from "./loading/PointsTurning";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { locale } from "numeral";

function Typography(props) {
  return null;
}

function EventList() {
  const [isLoading, setIsLoading] = useState(true); // État de chargement des requêtes
  const [eventsHaddock, setEventsHaddock] = useState([]);
  const [eventsMag, setEventsMag] = useState([]);
  const [allEvents, setAllEvents] = useState([]); // [eventsHaddock, eventsMag]
  const [selectedCategory, setSelectedCategory] = useState("0"); // Catégorie par défaut (Toutes les catégories)
  const [isFreeChecked, setIsFreeChecked] = useState(false); // État de la case à cocher "Gratuit"
  const [isTempoFreeChecked, setIsTempoFreeChecked] = useState(false); // État de la case à cocher "Gratuit"
  const [isPaidChecked, setIsPaidChecked] = useState(false); // État de la case à cocher "Payant"
  const [isTempoPaidChecked, setIsTempoPaidChecked] = useState(false); // État de la case à cocher "Payant"
  const [selectedCategories, setSelectedCategories] = useState([]); // Modifier pour utiliser un tableau pour stocker les catégories sélectionnées
  const [TemposelectedCategories, setTempoSelectedCategories] = useState([]); // Modifier pour utiliser un tableau pour stocker les catégories sélectionnées
  const [selectedOrganizers, setSelectedOrganizers] = useState([]); // Organisateur par défaut (Tous les organisateurs)
  const [TemposelectedOrganizers, setTempoSelectedOrganizers] = useState([]); // Organisateur par défaut (Tous les organisateurs)

  useEffect(() => {
    async function getEventsAndDetailsHaddock() {
      const mainApiUrl = "https://api.weezevent.com/events";
      const apiKey = process.env.REACT_APP_API_KEY_WE_HADDOCK;
      const accessTokenHaddock = process.env.REACT_APP_ACCESS_TOKEN_WE_HADDOCK;

      const mainApiParamsHaddock = new URLSearchParams({
        api_key: apiKey,
        access_token: accessTokenHaddock,
        include_without_sales: "true",
        include_not_published: "false",
      });

      try {
        const mainApiResponse = await fetch(
          `${mainApiUrl}?${mainApiParamsHaddock}`
        );
        if (!mainApiResponse.ok) {
          throw new Error(
            "Erreur lors de la récupération de la liste des événements"
          );
        }
        const eventData = await mainApiResponse.json();
        const { events } = eventData;

        const eventsWithDetails = [];

        for (const event of events) {
          const eventDetailsUrl = `https://api.weezevent.com/event/${event.id}/details?api_key=${apiKey}&access_token=${accessTokenHaddock}`;
          const eventDetailsResponse = await fetch(eventDetailsUrl);

          if (!eventDetailsResponse.ok) {
            console.error(
              `Erreur lors de la récupération des détails de l'événement ${event.id}`
            );
            continue;
          }

          const eventDetails = await eventDetailsResponse.json();
          // console.log(`Détails de l'événement ${event.id}:`, eventDetails);

          eventsWithDetails.push({ event, details: eventDetails });
        }

        // Tri des événements par date de début
        eventsWithDetails.sort((a, b) => {
          return (
            new Date(a.details.events.period.start) -
            new Date(b.details.events.period.start)
          );
        });

        setEventsHaddock(eventsWithDetails);
      } catch (error) {
        console.error(error.message);
      }
    }

    async function getEventsAndDetailsMag() {
      const mainApiUrl = "https://api.weezevent.com/events";
      const apiKey = process.env.REACT_APP_API_KEY_WE_MAG;
      const accessTokenMag = process.env.REACT_APP_ACCESS_TOKEN_WE_MAG;

      const mainApiParamsMag = new URLSearchParams({
        api_key: apiKey,
        access_token: accessTokenMag,
        include_without_sales: "true",
        include_not_published: "false",
      });

      try {
        const mainApiResponse = await fetch(
          `${mainApiUrl}?${mainApiParamsMag}`
        );
        if (!mainApiResponse.ok) {
          throw new Error(
            "Erreur lors de la récupération de la liste des événements"
          );
        }

        const eventData = await mainApiResponse.json();

        const { events } = eventData;

        const eventsWithDetails = [];

        for (const event of events) {
          const eventDetailsUrl = `https://api.weezevent.com/event/${event.id}/details?api_key=${apiKey}&access_token=${accessTokenMag}`;
          const eventDetailsResponse = await fetch(eventDetailsUrl);

          if (!eventDetailsResponse.ok) {
            console.error(
              `Erreur lors de la récupération des détails de l'événement ${event.id}`
            );
            continue;
          }

          const eventDetails = await eventDetailsResponse.json();
          // console.log(`Détails de l'événement ${event.id}:`, eventDetails);

          eventsWithDetails.push({ event, details: eventDetails });
        }

        // Tri des événements par date de début
        eventsWithDetails.sort((a, b) => {
          return (
            new Date(a.details.events.period.start) -
            new Date(b.details.events.period.start)
          );
        });

        setEventsMag(eventsWithDetails);
      } catch (error) {
        console.error(error.message);
      }
    }

    const filteredEventsHaddock =
      selectedCategory === "0"
        ? eventsHaddock
        : eventsHaddock.filter(
            ({ details }) =>
              details.events.category.id.toString() === selectedCategory
          );

    const filteredEventsMag =
      selectedCategory === "0"
        ? eventsMag
        : eventsMag.filter(
            ({ details }) =>
              details.events.category.id.toString() === selectedCategory
          );

    getEventsAndDetailsMag();
    getEventsAndDetailsHaddock();
    setAllEvents([...filteredEventsHaddock, ...filteredEventsMag]);

    Promise.all([getEventsAndDetailsHaddock(), getEventsAndDetailsMag()])
      .then(() => {
        setIsLoading(false); // Mettre isLoading à false lorsque toutes les requêtes sont terminées
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des événements:", error);
        setIsLoading(false); // Assurez-vous de gérer les erreurs et de définir isLoading à false en cas d'échec
      });
  }, []);

  useEffect(() => {
    // Combinez les événements Haddock et Mag dans un seul tableau
    const combinedEvents = [...eventsHaddock, ...eventsMag];

    // Triez le tableau combiné par date de début de l'événement
    combinedEvents.sort((a, b) => {
      return (
        new Date(a.details.events.period.start) -
        new Date(b.details.events.period.start)
      );
    });

    // Mettez à jour l'état avec les événements triés
    setAllEvents(combinedEvents);
  }, [eventsHaddock, eventsMag]); // Assurez-vous de mettre à jour lorsque les événements Haddock ou Mag changent

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, category]); // Ajouter la catégorie sélectionnée au tableau
    } else {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      ); // Retirer la catégorie désélectionnée du tableau
      console.log("Catégories sélectionnées:", selectedCategories);
    }
    setTempoSelectedCategories(selectedCategories); // Ajouter la catégorie sélectionnée au tableau mobile
  };
  const handleTempoCategoryChange = (event) => {
    const category = event.target.value;
    if (event.target.checked) {
      setTempoSelectedCategories([...TemposelectedCategories, category]); // Ajouter la catégorie sélectionnée au tableau
      console.log("Catégories sélectionnées:", TemposelectedCategories);
      console.log("tous les événements:", allEvents);
    } else {
      setTempoSelectedCategories(
        TemposelectedCategories.filter((cat) => cat !== category)
      ); // Retirer la catégorie désélectionnée du tableau
      console.log("Catégories sélectionnées:", TemposelectedCategories);
    }
  };
  const handleFreeChange = (event) => {
    setIsFreeChecked(event.target.checked);
    setIsTempoFreeChecked(isFreeChecked);
  };
  const handleTempoFreeChange = (event) => {
    setIsTempoFreeChecked(event.target.checked);
  };

  const handleOrganizerChange = (event) => {
    const organizer = event.target.value;
    if (event.target.checked) {
      setSelectedOrganizers([...selectedOrganizers, organizer]);
    } else {
      setSelectedOrganizers(
        selectedOrganizers.filter((org) => org !== organizer)
      );
    }
    // Mettre à jour les organisateurs sélectionnés temporaires
    setTempoSelectedOrganizers(selectedOrganizers);
  };
  const handleTempoOrganizerChange = (event) => {
    const organizer = event.target.value;
    if (event.target.checked) {
      setTempoSelectedOrganizers([...TemposelectedOrganizers, organizer]);
    } else {
      setTempoSelectedOrganizers(
        TemposelectedOrganizers.filter((org) => org !== organizer)
      );
    }
  };

  const formatPrice = (price) => {
    if (price === "0.00" || price === null) {
      return "Gratuit";
    } else {
      return `${price} €`;
    }
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: "Europe/Paris", // Assure-toi de définir le bon fuseau horaire
      locale: frLocale,
    };

    const formattedDate = format(
      date,
      "EEEE dd MMMM" + " à " + "HH:mm",
      options
    );

    // Mettre la première lettre en majuscule pour les noms des jours
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  const getCategoryName = (categoryId) => {
    switch (categoryId) {
      case "37":
        return "Adhésions et dons";
      case "33":
        return "Ateliers et cours";
      case "6":
        return "Concerts";
      case "35":
        return "Conférences et séminaires";
      case "7":
        return "Festival";
      case "8":
        return "Spectacles";
      case "13":
        return "Sport et sessions loisirs";
      case "39":
        return "Autres";
      default:
        return categoryId;
    }
  };

  const handleEventClick = (eventUrl) => {
    window.open(eventUrl, "_blank");
    console.log("URL de l'événement:", eventUrl);
  };

  const currentDate = new Date();

  //Gestion de la boîte de dialogue de filtrage
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Gestion des filtres version mobile
  const handleFilterAtOnce = () => {
    // Mettre à jour les états principaux avec les valeurs temporaires
    setIsFreeChecked(isTempoFreeChecked);
    setIsPaidChecked(isTempoPaidChecked);
    setSelectedCategories(TemposelectedCategories);
    setSelectedOrganizers(TemposelectedOrganizers);
    // Fermer la boîte de dialogue
    handleClose();
  };

  // Filtrer les événements avec des dates multiples
  const eventsWithMultipleDates = allEvents.filter(
    (event) => event.details.events.dates.length > 0
  );

  // Traiter les événements avec des dates multiples
  const eventsWithMultipleDatesProcessed = eventsWithMultipleDates.flatMap(
    (event) => {
      // Créer un événement pour chaque date dans les événements avec des dates multiples
      return event.details.events.dates.map((date) => ({
        ...event,
        details: {
          ...event.details,
          events: {
            ...event.details.events,
            date: {
              start: date.start,
              end: date.end,
            },
          },
        },
      }));
    }
  );

  // Filtrer les événements sans dates multiples et les traiter
  const eventsWithOneDate = allEvents.filter(
    (event) => event.details.events.dates.length === 0
  );

  // Fusionner les événements filtrés sans dates multiples avec les événements traités avec des dates multiples
  const finalEventsList = [
    ...eventsWithOneDate,
    ...eventsWithMultipleDatesProcessed,
  ];

  const filteredEvents = finalEventsList
    .flatMap((event) => {
      if (event.details.events.dates.length > 0) {
        // Si l'événement a des dates de séance, on crée un événement pour chaque date
        return event.details.events.dates.map((date) => ({
          ...event,
          details: {
            ...event.details,
            events: {
              ...event.details.events,
              period: {
                timezone: date.timezone,
                start: date.start,
                end: date.end,
              },
            },
          },
        }));
      } else {
        // Si l'événement n'a pas de dates de séance, on conserve l'événement tel quel
        return [event];
      }
    })
    .filter((event) => {
      // On vérifie la condition de date pour tous les événements
      return (
        new Date(event.details.events.period.start) >= currentDate &&
        // Conditions de filtrage ici
        (selectedCategories.length === 0 ||
          selectedCategories.includes(
            event.details.events.category.id.toString()
          )) &&
        ((isFreeChecked &&
          event.details.events.price.max === "0.00" &&
          event.details.events.price.min === "0.00") ||
          (isPaidChecked &&
            (event.details.events.price.max !== "0.00" ||
              event.details.events.price.min !== "0.00")) ||
          (!isFreeChecked && !isPaidChecked)) &&
        (selectedOrganizers.length === 0 ||
          selectedOrganizers.includes(event.event.id_orga))
      );
    });

  // Tri des événements par date
  filteredEvents.sort((a, b) => {
    const dateA = new Date(a.details.events.period.start);
    const dateB = new Date(b.details.events.period.start);
    return dateA - dateB;
  });

  const uniqueEvents = {};

  // Filtrer les événements uniques en utilisant un objet comme dictionnaire
  const uniqueFilteredEvents = filteredEvents.filter((event) => {
    // Générer une clé unique pour chaque événement basée sur le titre et la date
    const eventKey = `${event.details.events.title}_${event.details.events.period.start}`;
    // Vérifier si la clé existe déjà dans l'objet uniqueEvents
    if (!uniqueEvents[eventKey]) {
      // Si la clé n'existe pas, ajouter l'événement à l'objet et retourner true pour le conserver
      uniqueEvents[eventKey] = true;
      return true;
    }
    // Si la clé existe déjà, retourner false pour filtrer l'événement
    return false;
  });

  return (
    <div>
      <section className="hero">
        <div className="maxing-width">
          <div className="hero-top">
            <div className="hero-img">
              <img
                src="https://www.mouleagaufres.com/wp-content/uploads/2023/09/Atelier-2-1536x1192.png"
                alt="illu progra"
              />
            </div>
            <div className="hero-text">
              <div>
                <h3>L'USINE À SPECTACLE</h3>
                <h1>La programmation !</h1>
                <div className="hero-img-mobile">
                  <img
                    src="https://www.mouleagaufres.com/wp-content/uploads/2023/09/Atelier-2-1536x1192.png"
                    alt="illu progra"
                  />
                </div>
                <p>
                  Le programme qui vous fera voyager tout l'été.... <br></br>
                  <br></br>Le Moule à Gaufres et ses résidents vous accueillent
                  autour de quarante soirées plus joyeuses les unes que les
                  autres !<br></br>
                  <br></br>
                  Nous collaborons avec l’association Haddock, qui propose des
                  ateliers créatifs variés comme la peinture, la sculpture et
                  bien d’autres.<br></br>
                </p>
              </div>
            </div>
          </div>
          <div className={"hero-bottom"}>
            <div className={"hero-images"}>
              <img src={"/img/mag4.jpg"} alt={"Moule à gaufres la nuit"} />
            </div>
            <div className={"hero-images"}>
              <img src={"/img/mag2.JPG"} alt={"Moule à gaufres la nuit"} />
            </div>
            <div className={"hero-images"}>
              <img src={"/img/mag5.jpg"} alt={"Moule à gaufres la nuit"} />
            </div>
          </div>
        </div>
      </section>

      <div className={"maxing-width"}>
        <div className="event-list">
          <div className="category-filter">
            <div className={"titre-filtre"}>
              <img src={"/img/filter.png"} alt={"filtrer"} />
              <h3>Filtrer</h3>
            </div>

            <div className="free-organisation">
              <div className={"tarif-categorie"}>
                <h5 id="tarifs">Tarif</h5>
                <FormControlLabel
                  className="category-checkbox"
                  control={
                    <Checkbox
                      checked={isFreeChecked}
                      onChange={handleFreeChange}
                      sx={{
                        color: "#637381",
                        "&.Mui-checked": {
                          color: "#ED6A1F",
                        },
                      }}
                    />
                  }
                  label="Gratuit"
                />

                <FormControlLabel
                  className="category-checkbox"
                  control={
                    <Checkbox
                      checked={isPaidChecked}
                      onChange={(e) => setIsPaidChecked(e.target.checked)}
                      sx={{
                        color: "#637381",
                        "&.Mui-checked": {
                          color: "#ED6A1F",
                        },
                      }}
                    />
                  }
                  label="Payant"
                />
              </div>

              <div className="organisations">
                <h5 id="categories">
                  <strong>Organisateur</strong>
                </h5>
                <FormControlLabel
                  className="category-checkbox"
                  control={
                    <Checkbox
                      checked={selectedOrganizers.includes("493591")} // Vérifiez si Mag est sélectionné
                      onChange={handleOrganizerChange}
                      value="493591" // Valeur pour Mag
                      sx={{
                        color: "#637381",
                        "&.Mui-checked": {
                          color: "#ED6A1F",
                        },
                      }}
                    />
                  }
                  label="Moule à Gaufres"
                />
                <FormControlLabel
                  className="category-checkbox"
                  control={
                    <Checkbox
                      checked={selectedOrganizers.includes("493349")} // Vérifiez si Haddock est sélectionné
                      onChange={handleOrganizerChange}
                      value="493349" // Valeur pour Haddock
                      sx={{
                        color: "#637381",
                        "&.Mui-checked": {
                          color: "#ED6A1F",
                        },
                      }}
                    />
                  }
                  label="Haddock"
                />
              </div>
            </div>
            <div className="categories">
              <h5 id="categories">
                <strong>Catégories</strong>
              </h5>
              <div className="sidebar-categories">
                <Grid item xs={12} sm={4}>
                  {[
                    { id: "37", name: "Adhésions et dons" },
                    { id: "33", name: "Ateliers et cours" },
                    { id: "6", name: "Concerts" },
                    { id: "35", name: "Conférences et séminaires" },
                    { id: "7", name: "Festival" },
                    { id: "8", name: "Spectacles" },
                    { id: "13", name: "Sport et sessions loisirs" },
                    { id: "39", name: "Autres" },
                  ].map((category) => (
                    <FormControlLabel
                      className="category-checkbox"
                      key={category.id}
                      control={
                        <Checkbox
                          checked={selectedCategories.includes(category.id)}
                          onChange={handleCategoryChange}
                          value={category.id}
                          sx={{
                            color: "#637381",
                            "&.Mui-checked": {
                              color: "#ED6A1F",
                            },
                          }}
                        />
                      }
                      label={category.name}
                    />
                  ))}
                </Grid>
              </div>
            </div>
          </div>
          <div className="category-filter-mobile">
            <Button className={"mobile-filter-button"} onClick={handleOpen}>
              <img src={"/img/filter.svg"} alt={"filtrer"} />
              <p>Filtrer</p>
            </Button>

            <Modal
              open={open}
              onClose={handleClose}
              className={"mobile-modal-wrapper"}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="modal-filter">
                <div className={"titre-filtre"}>
                  <div className={"left-titre-filtre"}>
                    <img src={"/img/filter.png"} alt={"filtrer"} />
                    <h3>Filtrer</h3>
                  </div>
                  <Button onClick={handleClose} className={"close"}>
                    <img src={"/img/croix.svg"} alt={"close"} />
                  </Button>
                </div>
                <div className={"filter-choices"}>
                  <div className={"tarif-categorie"}>
                    <h5 id="tarifs">
                      <strong>Tarif</strong>
                    </h5>
                    <FormControlLabel
                      className="category-checkbox"
                      control={
                        <Checkbox
                          checked={isTempoFreeChecked}
                          onChange={handleTempoFreeChange}
                          sx={{
                            color: "#637381",
                            "&.Mui-checked": {
                              color: "#ED6A1F",
                            },
                          }}
                        />
                      }
                      label="Gratuit"
                    />
                    <FormControlLabel
                      className="category-checkbox"
                      control={
                        <Checkbox
                          checked={isTempoPaidChecked}
                          onChange={(e) =>
                            setIsTempoPaidChecked(e.target.checked)
                          }
                          sx={{
                            color: "#637381",
                            "&.Mui-checked": {
                              color: "#ED6A1F",
                            },
                          }}
                        />
                      }
                      label="Payant"
                    />
                  </div>

                  <div className="organisations">
                    <h5 id="categories">
                      <strong>Organisateur</strong>
                    </h5>
                    <FormControlLabel
                      className="category-checkbox"
                      control={
                        <Checkbox
                          checked={TemposelectedOrganizers.includes("493591")} // Vérifiez si Mag est sélectionné
                          onChange={handleTempoOrganizerChange}
                          value="493591" // Valeur pour Mag
                          sx={{
                            color: "#637381",
                            "&.Mui-checked": {
                              color: "#ED6A1F",
                            },
                          }}
                        />
                      }
                      label="Moule à Gaufres"
                    />
                    <FormControlLabel
                      className="category-checkbox"
                      control={
                        <Checkbox
                          checked={TemposelectedOrganizers.includes("493349")} // Vérifiez si Haddock est sélectionné
                          onChange={handleTempoOrganizerChange}
                          value="493349" // Valeur pour Haddock
                          sx={{
                            color: "#637381",
                            "&.Mui-checked": {
                              color: "#ED6A1F",
                            },
                          }}
                        />
                      }
                      label="Haddock"
                    />
                  </div>
                  <div className="categories">
                    <h5 id="categories">
                      <strong>Catégories</strong>
                    </h5>
                    <div className="sidebar-categories">
                      <Grid item xs={12} sm={4}>
                        {[
                          { id: "37", name: "Adhésions et dons" },
                          { id: "33", name: "Ateliers et cours" },
                          { id: "6", name: "Concerts" },
                          { id: "35", name: "Conférences et séminaires" },
                          { id: "7", name: "Festival" },
                          { id: "8", name: "Spectacles" },
                          { id: "13", name: "Sport et sessions loisirs" },
                          { id: "39", name: "Autres" },
                        ].map((category) => (
                          <FormControlLabel
                            className="category-checkbox"
                            key={category.id}
                            control={
                              <Checkbox
                                checked={TemposelectedCategories.includes(
                                  category.id
                                )}
                                onChange={handleTempoCategoryChange}
                                value={category.id}
                                sx={{
                                  color: "#637381",
                                  "&.Mui-checked": {
                                    color: "#ED6A1F",
                                  },
                                }}
                              />
                            }
                            label={category.name}
                          />
                        ))}
                      </Grid>
                    </div>
                  </div>
                </div>
                <Button onClick={handleFilterAtOnce} className={"validate-btn"}>
                  Valider
                </Button>
              </div>
            </Modal>
          </div>
          {isLoading ? (
            <PointsTurning />
          ) : (
            <div className="events-list">
              {uniqueFilteredEvents.length > 0 ? (
                uniqueFilteredEvents.map((event) => (
                  <div
                    key={event.id}
                    className="event-details-card"
                    onClick={() =>
                      handleEventClick(event.details.events.extras.minisite_url)
                    }
                    role="button"
                    tabIndex={0}
                    onKeyPress={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        handleEventClick(details.events.extras.minisite_url);
                      }
                    }}
                  >
                    {" "}
                    <div className="event-image-container">
                      <img
                        src={event.details.events.image}
                        alt="Visuel de l'événement"
                      />
                      <p className="event-price">
                        {formatPrice(event.details.events.price.max)}
                      </p>
                    </div>
                    <div className="event-infos">
                      <p className="start-date">
                        {formatDate(event.details.events.period.start)}{" "}
                        {/* Modification ici */}
                      </p>
                      <h2 className="event-title">
                        {event.details.events.title}
                      </h2>
                      <p className="event-category">
                        {getCategoryName(event.details.events.category.id)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-events">
                  <h5>Aucun événement correspondant trouvé</h5>
                </div>
              )}
        <div className="soutien">
          <div className="soutien-img">
          <h4>Avec le soutien de </h4>
            <img
              src="https://www.mouleagaufres.com/wp-content/uploads/2024/07/Logo-interives_.png"
              alt="logo interives"
            />
            <img
              src="https://www.mouleagaufres.com/wp-content/uploads/2024/07/logo_semdo_Q.jpeg"
              alt="logo semdo"
            />
          </div>
        </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventList;
