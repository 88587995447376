import React, {useState} from "react";
import {useMyContext} from "../MyContext";
import "../style/reservationSummary.css";
import axios from "axios";
import HorizontalSeparator from "./separators/HorizontalSeparator";
import DashedHorizontalSeparator from "./separators/DashedHorizontalSeparator";
import Cookies from "js-cookie";
import {Button, Typography} from "@mui/material";
import {LoginForm} from "./forms/login";
import RegisterForm from "./forms/register/RegisterForm";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {toast} from "react-toastify";


const ReservationSummary = () => {
    const {
        formattedDates,
        formattedDate,
        openDaysCount,
        selectedMonths,
        selectedStartTime,
        selectedEndTime,
        nb_postes,
        salle,
        earliestHour,
        latestHour,
        selectedTimeRanges,
        selectedOptionValue,
        salleId,
        selectedEndMonth,
        selectedStartMonth,
        selectedDate,
        token,
        isUserLoggedIn,
        startIndex,
        endIndex,
        startYearValue,
        endYearValue,
        type,
        totalPrixEquipements,
        prixSelectionnes,
        selectedStartYear,
        selectedEndYear,
        selectedServices,
        totalPrixServices,
    } = useMyContext();

    const [isSendingInvoice, setIsSendingInvoice] = useState(false);
    const [invoiceSent, setInvoiceSent] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [registered, setRegistered] = useState(true);
    const userEmail = Cookies.get("userEmail");
    let serverResponseData = null;
    const apiUrl = process.env.REACT_APP_API_URL;

    const sendEvoliz = async () => {
        try {
            setIsSendingInvoice(true);
            const response = await axios.post(
                `${apiUrl}/evoliz/create_send_invoice`,
                {
                    formattedDates: formattedDates,
                    selectedDate: selectedDate,
                    openDaysCount: openDaysCount,
                    selectedMonths: selectedMonths,
                    selectedStartTime: selectedStartTime,
                    selectedEndTime: selectedEndTime,
                    nb_postes: nb_postes,
                    earliestHour: earliestHour,
                    latestHour: latestHour,
                    selectedTimeRanges: selectedTimeRanges,
                    salleId: salleId,
                    selectedEndMonth: selectedEndMonth,
                    selectedStartMonth: selectedStartMonth,
                    selectedOptionValue: selectedOptionValue,
                    startIndex: startIndex,
                    endIndex: endIndex,
                    startYearValue: startYearValue,
                    endYearValue: endYearValue,
                    equipements: prixSelectionnes,
                    selectedServices: selectedServices,
                },
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );

            serverResponseData = response.data;
            const link = serverResponseData.data.webdoc;

            const newTab = window.open(link, "_blank");

            if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
                // Si l'ouverture est bloquée ou si le navigateur n'a pas pu ouvrir le nouvel onglet
                toast.error(
                    <div>
                        L'ouverture de la facture dans un nouvel onglet est bloquée par votre navigateur.
                        Vous pouvez ouvrir manuellement la facture
                        <a href={link} target="_blank" rel="noopener noreferrer">en cliquant sur ce lien</a>
                    </div>,
                    {
                        autoClose: false
                    }
                );
            }
            setInvoiceSent(true);
            setIsSendingInvoice(false);
            console.log("Facture envoyée avec succès.");
            await checkStatus();
            //console.log(setIsPaid);
        } catch (error) {
            setIsSendingInvoice(false);
            console.log(error);
            alert("Une erreur est survenue lors de la réservation sur le front. Veuillez réessayer.");
        }
    };
    //console.log(serverResponseData);
    //console.log(userEmail);
    // Vérifier le statut du paiement
    const checkStatus = async () => {
        try {
            const response = await axios.get(
                `${apiUrl}/evoliz/ispaid/${serverResponseData.data.invoiceid}`, {
                    headers: {
                        Authorization: "Bearer " + token,
                    }
                }
            );
            const isPaid = await response.data.isPaid;
            //console.log("Statut du paiement : ", isPaid);
            if (isPaid) {
                setIsPaid(true);
                //console.log("Le paiement a été effectué.");
            } else {
                //console.log("Le paiement n'a pas encore été effectué.");
                setTimeout(checkStatus, 5000); // Appeler checkStatus après 5 secondes
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la requête : ", error);
        }
    };

    return (
        <div className="reservation-summary-container">
            <div className="infos_salle_right_reservation infos_salle_right">
                {/* <div className="poincon poincon_left"></div> */}

                {/*selecteur heure/jour/mois*/}
                <img
                    src={salle.image_principale}
                    alt="salle"
                    className="image_principale"
                />
                <h2 className="salle-summary">Salle {salle.nom}</h2>
                {selectedOptionValue === "heure" && (
                    <div className="date_horaire">
                        <div>
                            <p>
                                Date<br></br>
                                {selectedDate.toLocaleDateString()}
                            </p>
                        </div>
                        <div>
                            <p>
                                Horaire<br></br>De {earliestHour}h à {latestHour}h
                            </p>
                        </div>
                    </div>
                )}
                {selectedOptionValue === "jour" && (
                    <div className="date_horaire">
                        <div>
                            <p>
                                Du<br></br>
                                {formattedDates[0]}
                            </p>
                        </div>
                        <div>
                            <p>
                                Au<br></br>
                                {formattedDates[1]}
                            </p>
                        </div>
                    </div>
                )}
                {selectedOptionValue === "mois" && (
                    <div className="date_horaire">
                        De {selectedStartMonth} {selectedStartYear} à {selectedEndMonth} {selectedEndYear}
                    </div>
                )}

                <DashedHorizontalSeparator/>
                <p>Récapitulatif</p>
                <div className="recap">
                    {selectedOptionValue === "heure" && (
                        <>
                            <div className="heure">
                                <p>{selectedTimeRanges.length} heure(s)</p>
                                {Boolean(type.reservable_par_poste) && (
                                    <p>{nb_postes} poste(s)</p>
                                )}
                            </div>

                            <div className="poste">
                                <p>
                                    {salle.prix_heure}€ HT X {selectedTimeRanges.length}
                                </p>
                                {Boolean(type.reservable_par_poste) && (
                                    <p id="nb_postes">x{nb_postes}</p>
                                )}
                            </div>
                        </>
                    )}

                    {selectedOptionValue === "jour" && (
                        <>
                            <p>{openDaysCount} journée(s)</p>
                            <p>
                                {openDaysCount * salle.prix_journee}€ HT{" "}
                                {Boolean(type.reservable_par_poste) && (
                                    <em>X {nb_postes} poste(s)</em>
                                )}
                            </p>
                        </>
                    )}

                    {selectedOptionValue === "mois" && (
                        <>
                            <p>{selectedMonths} mois</p>
                            <p>
                                {selectedMonths * salle.prix_mois}€ HT
                                {Boolean(type.reservable_par_poste) && (
                                    <em>X {nb_postes} poste(s)</em>
                                )}
                            </p>
                        </>
                    )}
                </div>
                {prixSelectionnes.map((equipement) => (
                    <div className="recap" key={equipement.id}>
                        <p>{equipement.nom}</p>
                        <p>{equipement.prix}€</p>
                    </div>
                ))}
                {selectedServices.length > 0 && (
                    <div>
                        <p id="supplement" className={"sous-titres"}>
                            Services
                        </p>

                        {selectedServices.map((service) => (
                            <div className="recap" key={service.service.id}>
                                <p>{service.service.nom}</p>
                                <p>{service.service.prix_unitaire * service.people}€</p>
                            </div>
                        ))}
                    </div>
                        )}

                        <HorizontalSeparator/>

                        {selectedOptionValue === "heure" && (
                            <>
                                <div className="total">
                                    <p>Total</p>
                                    <p>
                                        {Boolean(type.reservable_par_poste)
                                            ? salle.prix_heure * nb_postes * selectedTimeRanges.length +
                                            totalPrixEquipements+ totalPrixServices
                                            : salle.prix_heure * selectedTimeRanges.length +
                                            totalPrixEquipements + totalPrixServices}
                                        € HT
                                    </p>
                                </div>
                            </>
                        )}

                        {selectedOptionValue === "jour" && (
                            <>
                                <div className="total">
                                    <p>Total</p>
                                    {Boolean(type.reservable_par_poste) ? (
                                        <p>
                                            {salle.prix_journee * nb_postes * openDaysCount +
                                                totalPrixEquipements + totalPrixServices}
                                            € HT
                                        </p>
                                    ) : (
                                        <p>
                                            {salle.prix_journee * openDaysCount + totalPrixEquipements + totalPrixServices}€
                                            HT
                                        </p>
                                    )}
                                </div>
                            </>
                        )}

                        {selectedOptionValue === "mois" && (
                            <>
                                <div className="total">
                                    <p>Total</p>
                                    {Boolean(type.reservable_par_poste) ? (
                                        <p>
                                            {selectedMonths * salle.prix_mois * nb_postes +
                                                totalPrixEquipements + totalPrixServices}
                                            € HT
                                        </p>
                                    ) : (
                                        <p>
                                            {selectedMonths * salle.prix_mois + totalPrixEquipements + totalPrixServices}€ HT
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                {!isPaid ? (
                    isUserLoggedIn ? (
                    <div className="proceder-paiement">
                    <h1 className="se-connecter">Vous y êtes presque !</h1>
                    <br/>
                    <h4 className="proceder">Procéder au paiement</h4>
                    <br/>
                    <p className="finaliser">
                    Pour finaliser votre réservation de salle, nous allons vous faire
                    parvenir la facture à l'adresse e-mail suivante :
                    </p>
                    <br/>
                    <p>{localStorage.getItem("userEmail")}</p>
            <br/>
            <p>
                <strong>Veuillez effectuer le paiement</strong> via notre
                plateforme partenaire Evoliz afin de conclure la réservation avec
                succès.
                <br></br>Le lien menant au module de paiement est disponible sur
                votre facture.
            </p>
            <br/>
            <p className="cgv">
                En cliquant sur le bouton ci-dessous, j'accepte les conditions
                suivantes : <a href="/CGV" className="cgv-lien">Conditions
                générales de vente</a> et <a href="https://www.mouleagaufres.com/confidentialite/"
                                             className="cgv-lien">politique de confidentialité</a>.
                <br></br>
                <br></br>Je m'engage à payer le montant total affiché.
            </p>
            <button
                className="send-evoliz"
                onClick={sendEvoliz}
                disabled={isSendingInvoice || invoiceSent}
            >
                {isSendingInvoice
                    ? "En cours..."
                    : invoiceSent
                        ? "Facture envoyée"
                        : "Recevoir votre facture"}
            </button>
        </div>
    )
:
    registered ? (
        <div className="reservation-form">
            <h2>Mais qui êtes-vous ?</h2>
            <br></br>
            <LoginForm redirectToHome={false}/>
            <Typography variant="body2" align="center" sx={{mt: 3}}>
                Vous n'avez pas de compte ?{" "}
                <Button
                    onClick={() => setRegistered(false)}
                    className="register-button"
                >
                    Inscription
                </Button>
            </Typography>
        </div>
    ) : (
        <div className="reservation-form">
            <h2>Mais qui êtes-vous ?</h2>
            <br></br>
            <RegisterForm redirectToHome={false}/>
            <Typography variant="body2" align="center" sx={{mt: 3}}>
                Déjà un compte ?{" "}
                <Button
                    onClick={() => setRegistered(true)}
                    className="register-button"
                >
                    Se connecter
                </Button>
            </Typography>
        </div>
    )
) :
    (
        <div>
            <h1 className="tout-bon">C'est tout bon !</h1>
            <img
                src="https://atelier.mouleagaufres.com/wp-content/uploads/2023/10/Clef.png"
                alt="clef"
                className="clef"
            />
            <div className="checked">
                <div className="reservee">
                    <p className="icon-circle">

                        <CheckCircleIcon style={{color: '#5AC27A', fontSize: 24}}/>
                    </p>

                    <p>Salle réservée</p>
                </div>
                <div className="payee">
                    <p className="icon-circle">

                        <CheckCircleIcon style={{color: '#5AC27A', fontSize: 24}}/>
                    </p>
                    <p>Facture payée</p>
                </div>
            </div>
        </div>
    )
}
</div>
);
};

export default ReservationSummary;
