import React, {useState, useEffect} from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {Formik, Form, Field, useFormik, FormikProvider} from "formik";
import {Chip, MenuItem, Stack, TextareaAutosize, TextField} from "@mui/material";
import {Autocomplete, LoadingButton} from "@mui/lab";
import {useMyContext} from "../../../MyContext";

export default function EspaceForm() {
    const {token} = useMyContext();
    const [isLoading, setIsLoading] = useState(false);
    const {espaceId} = useParams(); // Récupère l'ID passé dans l'URL
    const navigate = useNavigate();
    const [espace, setEspace] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [types, setTypes] = useState([]);
    const [avantages, setAvantages] = useState([]);
    const [selectedAvantages, setSelectedAvantages] = useState([]);

    useEffect(() => {
        setIsLoading(true); // Définir isLoading à true au début de la requête
        async function fetchData() {
            try {
                //Récupérer les infos de la salle
                const response = await axios.get(
                    `${apiUrl}/espaces/${espaceId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                );

                // Récupérer les types de salles
                const typesResponse = await axios.get(
                    `${apiUrl}/typesespaces`, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    });

                // Récupérer les avantages
                const avantagesResponse = await axios.get(
                    `${apiUrl}/avantages`, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    });

                // Récupérer les avantages de la salle
                const avantagesEspaceResponse = await axios.get(
                    `${apiUrl}/avantages/espace/${espaceId}`, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    });

                setEspace(response.data.salle);
                setTypes(typesResponse.data);
                setAvantages(avantagesResponse.data);
                setSelectedAvantages(avantagesEspaceResponse.data);
            } catch (error) {
                console.error(error);
                setIsLoading(false); // Définir isLoading à false en cas d'erreur
                throw error;
            }

        }

        fetchData();
    }, [espaceId, token]);

    useEffect(() => {
        if (espace.length !== 0) {
            setIsLoading(false); // Définir isLoading à false lorsque la requête est terminée
            setDataLoaded(true); // Indiquer que les données sont chargées
        }
    }, [espace, types]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nom: espace.nom,
            type_id: espace.type_id,
            jauge: espace.jauge || 0,
            etage: espace.etage || 0,
            prix_heure: espace.prix_heure || 0,
            prix_journee: espace.prix_journee || 0,
            prix_mois: espace.prix_mois || 0,
            google_calendar: espace.google_calendar || "",
            description: espace.description || "",
            details: espace.details || "",
            avantages: selectedAvantages.map(avantage => avantage.id),
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const updateResponse = await axios.put(
                    `${apiUrl}/espaces/${espaceId}`,
                    values,
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                );

                navigate("/addImages/" + espace.id, {replace: true});
            } catch (error) {
                console.error(error);
                alert("Une erreur est survenue lors de la modification de la salle. " +
                    "\nVeuillez en informer l'équipe technique.");
                throw error;
            }
            setIsLoading(false);
        },
    });
    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} =
        formik;

    if (!dataLoaded) {
        return (<div>Loading...</div>); // Ou affichez un spinner ou un autre indicateur de chargement
    }

    return (
        <div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <h1>Ajoutez une salle </h1>
                    <br></br>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nom"
                            {...getFieldProps("nom")}
                            error={Boolean(touched.nom && errors.nom)}
                            helperText={touched.nom && errors.nom}
                        />

                        <TextField
                            fullWidth
                            select
                            label="Type"
                            value={values.type_id}
                            onChange={(e) => setFieldValue("type_id", e.target.value)}
                            {...getFieldProps("type_id")}
                            error={Boolean(touched.type_id && errors.type_id)}
                            helperText={touched.type_id && errors.type_id}
                        >
                            {types.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.nom}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Autocomplete
                            multiple
                            id="avantages"
                            options={avantages}
                            value={selectedAvantages}
                            onChange={(event, newValue) => {
                                formik.setFieldValue("avantages", newValue.map((avantage) => avantage.id));
                                setSelectedAvantages(newValue);
                            }}
                            getOptionLabel={(option) => option.nom}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Avantages"
                                    error={Boolean(touched.avantages && errors.avantages)}
                                    helperText={touched.avantages && errors.avantages}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} label={option.nom} {...getTagProps({index})} />
                                ))
                            }
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="jauge"
                            {...getFieldProps("jauge")}
                            error={Boolean(touched.jauge && errors.jauge)}
                            helperText={touched.jauge && errors.jauge}
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="Etage"
                            {...getFieldProps("etage")}
                            error={Boolean(touched.etage && errors.etage)}
                            helperText={touched.etage && errors.etage}
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="Prix heure"
                            {...getFieldProps("prix_heure")}
                            error={Boolean(touched.prix_heure && errors.prix_heure)}
                            helperText={touched.prix_heure && errors.prix_heure}
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="Prix journee"
                            {...getFieldProps("prix_journee")}
                            error={Boolean(touched.prix_journee && errors.prix_journee)}
                            helperText={touched.prix_journee && errors.prix_journee}
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="Prix mois"
                            {...getFieldProps("prix_mois")}
                            error={Boolean(touched.prix_mois && errors.prix_mois)}
                            helperText={touched.prix_mois && errors.prix_mois}
                        />

                        <TextField
                            fullWidth
                            type="text"
                            label="Description"
                            {...getFieldProps("description")}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                        />

                        <TextField
                            multiline
                            minRows={3}
                            fullWidth
                            placeholder="Details"
                            {...getFieldProps("details")}
                            error={Boolean(touched.details && errors.details)}
                            helperText={touched.details && errors.details}
                        />

                        <TextField
                            fullWidth
                            type="text"
                            label="Google Calendar ID"
                            {...getFieldProps("google_calendar")}
                            error={Boolean(touched.google_calendar && errors.google_calendar)}
                            helperText={touched.google_calendar && errors.google_calendar}
                        />

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            id="register_button"
                        >
                            Continuer
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </div>
    );
}
