import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container } from '@mui/material';

const ForgotPassword = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [errorDetails, setErrorDetails] = useState(null); // Pour stocker les détails des erreurs

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/password/email`, { email });
      setMessage('Nous avons envoyé un lien de réinitialisation du mot de passe à votre adresse email.');
      setError('');
      setErrorDetails(null);
    } catch (error) {
      // Loger les détails complets de l'erreur
      console.error('Error response:', error.response);
      console.error('Error details:', error);

      // Mettre à jour l'état avec les détails de l'erreur
      setError(error.response.data.email || 'Une erreur est survenue. Veuillez réessayer.');
      setErrorDetails({
        status: error.response?.status,
        headers: error.response?.headers,
        data: error.response?.data,
        message: error.message,
        stack: error.stack,
      });
      setMessage('');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Mot de passe oublié
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Adresse email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={{ marginBottom: '24px' }} // Ajout de marge en bas du champ
        />
        <Button type="submit" variant="contained" color="primary" fullWidth style={{ textTransform: 'none', backgroundColor: '#FF5733' }}>
          Envoyer le lien de réinitialisation
        </Button>
      </form>
      {message && <Typography color="primary" style={{ marginTop: '16px' }}>{message}</Typography>}
      {error && <Typography color="error" style={{ marginTop: '16px' }}>{error}</Typography>}
      {errorDetails && (
        <div style={{ marginTop: '16px', color: 'red' }}>
          <Typography variant="h6">Détails de l'erreur :</Typography>
          <pre>{JSON.stringify(errorDetails, null, 2)}</pre>
        </div>
      )}
    </Container>
  );
};

export default ForgotPassword;
