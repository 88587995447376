import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Chip, MenuItem, Stack, TextField, Box, Typography } from "@mui/material";
import { Autocomplete, LoadingButton } from "@mui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMyContext } from "../MyContext";

// ----------------------------------------------------------------------

export default function AddEspaceForm() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const { token } = useMyContext();

    const [types, setTypes] = useState([]);
    const [avantages, setAvantages] = useState([]);
    const [selectedAvantages, setSelectedAvantages] = useState([]);

    // Récupérer les types de salle
    useEffect(() => {
        const requete = axios.create({
            baseURL: `${apiUrl}/typesespaces`,
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        requete.get().then((response) => {
            setTypes(response.data);
        });
    }, [apiUrl, token]);

    // Récupérer les avantages
    useEffect(() => {
        const requete = axios.create({
            baseURL: `${apiUrl}/avantages`,
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        requete.get().then((response) => {
            setAvantages(response.data);
        });
    }, [apiUrl, token]);

    const formik = useFormik({
        initialValues: {
            nom: "",
            type_id: '1',
            jauge: "",
            etage: "",
            prix_heure: "",
            prix_journee: "",
            prix_mois: "",
            google_calendar: "",
            description: "",
            details: "",
            avantages: [],
        },
        onSubmit: async (values) => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;

                const requestOptions = {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                    },
                };
                const response = await axios.post(
                    `${apiUrl}/espaces`, // Ne surtout pas laisser un '/' à la fin sous peine d'erreur CORS
                    values,
                    requestOptions
                )
                navigate("/addImages/" + response.data.id, { replace: true });
            } catch (error) {
                alert("Une erreur est survenue lors de l'ajout de l'image. Veuillez vérifier les détails dans la console.");
                console.error("Message d'erreur:", error.message);
            
                if (error.response) {
                    console.error("Code de statut:", error.response.status);
                    console.error("En-têtes de la réponse:", error.response.headers);
                    console.error("Données de la réponse:", error.response.data);
                } else {
                    console.error("Erreur sans réponse:", error);
                }
            }
        },
    });
    const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Ajoutez une salle
            </Typography>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            autoComplete="nom"
                            type="text"
                            label="Nom"
                            {...getFieldProps("nom")}
                            error={Boolean(touched.nom && errors.nom)}
                            helperText={touched.nom && errors.nom}
                        />

                        <TextField
                            fullWidth
                            select
                            label="Type"
                            value={values.type_id}
                            onChange={(e) => setFieldValue("type_id", e.target.value)}
                            {...getFieldProps("type_id")}
                            error={Boolean(touched.type_id && errors.type_id)}
                            helperText={touched.type_id && errors.type_id}
                        >
                            {types.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.nom}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Autocomplete
                            multiple
                            id="avantages"
                            options={avantages}
                            value={selectedAvantages}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "avantages",
                                    newValue.map((avantage) => avantage.id)
                                );
                                setSelectedAvantages(newValue);
                            }}
                            getOptionLabel={(option) => option.nom}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Avantages"
                                    error={Boolean(touched.avantages && errors.avantages)}
                                    helperText={touched.avantages && errors.avantages}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option.nom}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />

                        <TextField
                            fullWidth
                            autoComplete="jauge"
                            type="jauge"
                            label="Jauge"
                            {...getFieldProps("jauge")}
                            error={Boolean(touched.jauge && errors.jauge)}
                            helperText={touched.jauge && errors.jauge}
                        />

                        <TextField
                            fullWidth
                            type="number"
                            label="Etage"
                            {...getFieldProps("etage")}
                            error={Boolean(touched.etage && errors.etage)}
                            helperText={touched.etage && errors.etage}
                        />

                        <TextField
                            fullWidth
                            autoComplete="prix_heure"
                            type="number"
                            label="Prix heure"
                            {...getFieldProps("prix_heure")}
                            error={Boolean(touched.prix_heure && errors.prix_heure)}
                            helperText={touched.prix_heure && errors.prix_heure}
                        />

                        <TextField
                            fullWidth
                            autoComplete="prix_journee"
                            type="number"
                            label="Prix journee"
                            {...getFieldProps("prix_journee")}
                            error={Boolean(touched.prix_journee && errors.prix_journee)}
                            helperText={touched.prix_journee && errors.prix_journee}
                        />

                        <TextField
                            fullWidth
                            autoComplete="prix_mois"
                            type="number"
                            label="Prix mois"
                            {...getFieldProps("prix_mois")}
                            error={Boolean(touched.prix_mois && errors.prix_mois)}
                            helperText={touched.prix_mois && errors.prix_mois}
                        />

                        <TextField
                            fullWidth
                            autoComplete="description"
                            type="text"
                            label="Description"
                            {...getFieldProps("description")}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                        />

                        <TextField
                            multiline
                            minRows={3}
                            fullWidth
                            placeholder="Details"
                            {...getFieldProps("details")}
                            error={Boolean(touched.details && errors.details)}
                            helperText={touched.details && errors.details}
                        />

                        <TextField
                            fullWidth
                            autoComplete="google_calendar"
                            type="text"
                            label="Google Calendar ID"
                            {...getFieldProps("google_calendar")}
                            error={Boolean(touched.google_calendar && errors.google_calendar)}
                            helperText={touched.google_calendar && errors.google_calendar}
                        />

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            id="register_button"
                        >
                            Continuer
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </Box>
    );
}
