import React, { useState } from "react";
import { Typography, Box, Button, Collapse, Container } from "@mui/material";
import ListeDemandes from "../components/ListeDemandes";
import ManageManagersEmail from "./ManageManagersEmail";

export default function ManageDemandes() {
    const [showDemandes, setShowDemandes] = useState(true); // État initial pour afficher les demandes de devis par défaut

    const handleToggle = () => {
        setShowDemandes((prevShowDemandes) => !prevShowDemandes);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">
                    {showDemandes ? "Demandes de devis" : "Gestion des adresses emails"}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggle}
                >
                    {showDemandes ? "Gérer les adresses emails recevant les demandes" : "Voir les demandes de devis"}
                </Button>
            </Box>

            <Collapse in={showDemandes}>
                <ListeDemandes />
            </Collapse>

            <Collapse in={!showDemandes}>
                <ManageManagersEmail />
            </Collapse>
        </Container>
    );
}
