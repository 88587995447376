import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import "../style/photosManager.css";
import { useMyContext } from "../MyContext";

export default function PhotosManager() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { token } = useMyContext();

    const photosApi = axios.create({
        baseURL: `${apiUrl}`,
    });

    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const responsePhotos = await photosApi.get("/photos_carousel", {
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                });
                const sortedPhotos = responsePhotos.data.sort((a, b) => a.id - b.id);
                setPhotos(sortedPhotos);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des photos.", error);
            }
        };

        fetchPhotos();
    }, []);

    const onDrop = async (acceptedFiles) => {
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
            formData.append('image', file);
        });

        try {
            const response = await photosApi.post("/photos_carousel", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + token,
                }
            });
            setPhotos([...photos, response.data]);
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout de la photo.", error);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleDelete = async (id, e) => {
        e.stopPropagation(); // Empêche le déclenchement du drag and drop
        try {
            await photosApi.delete(`/photos_carousel/${id}`, {
                headers: {
                    'Authorization': "Bearer " + token,
                }
            });
            setPhotos(photos.filter(photo => photo.id !== id));
        } catch (error) {
            console.error("Une erreur s'est produite lors de la suppression de la photo.", error);
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedPhotos = Array.from(photos);
        const [movedPhoto] = reorderedPhotos.splice(result.source.index, 1);
        reorderedPhotos.splice(result.destination.index, 0, movedPhoto);

        setPhotos(reorderedPhotos);
    };

    return (
        <div className="photosManagerContainer">
            <h2>Gestion du Carousel</h2>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="photosManager" direction="horizontal">
                    {(provided) => (
                        <div className="dropzone" {...getRootProps()} {...provided.droppableProps} ref={provided.innerRef}>
                            <input {...getInputProps()} />
                            <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
                            {photos.length > 0 && (
                                <div className="photosContainer">
                                    {photos.map((photo, index) => (
                                        <Draggable key={photo.id} draggableId={String(photo.id)} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="photoItem"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <img src={photo.url} alt={photo.title} className="photoThumbnail" />
                                                    <button
                                                        onClick={(e) => handleDelete(photo.id, e)}
                                                        className="deleteButton"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}
