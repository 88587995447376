import React, {useEffect} from 'react';
import {useMyContext} from "../MyContext";

export default function Logout() {
    const {
        setToken,
        setUserRole,
        token,
    } = useMyContext();

    const handleLogout = () => {
        // Réinitialiser les credentials du context
        setUserRole("client");
        setToken("");


        // Rediriger
        window.location.href = "/";
    };

    useEffect(() => {
        handleLogout();
    }, [token]);

    return (
        <div></div>
    );
};

