import React, {useEffect} from "react";
import ListeSalles from "../components/ListeSalles";
import { useState } from "react";


export default function EcommerceShop() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };


  return (
      <div className="roomlist-wrapper">
        <div className="titres_salles">
          <h5 className="titre_ça">
            AH ÇA, ÇA M'INTÉRESSE
          </h5>
          <h2
            className="titre_salles_bureaux"
          >
            Découvrez nos salles<br></br> et nos bureaux
          </h2>
        </div>

        <ListeSalles />
      </div>
  );
}
