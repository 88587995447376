import axios from "axios";
import {React, useEffect, useState} from "react";
import {Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import GenericMoreMenu from "./GenericMoreMenu";
import {useMyContext} from "../MyContext";
import '../style/GenericList.css';
import PropTypes from "prop-types";

export default function GenericList(props) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { token } = useMyContext();

    const { reinitData } = useMyContext();

    const items = axios.create({
        baseURL: `${apiUrl}/${props.item}`,
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // État pour gérer le chargement

    useEffect(() => {
        setLoading(true); // Démarrer le chargement
        items.get().then((response) => {
            setData(response.data);
            setLoading(false); // Fin du chargement lorsque les données sont récupérées
        }).catch(() => {
            setLoading(false); // Fin du chargement même en cas d'erreur
        });
    }, [reinitData]);

    const renderTableHeader = () => {
        if (data.length === 0) {
            return null; // Pas de données, donc pas de header
        }

        const formatKey = (key) => {
            return key.replace(/[_-]/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        };

        const headerKeys = Object.keys(data[0]).filter(key => !props.keysToExclude.includes(key)).map(formatKey);

        return (
            <TableHead className={"list-head"}>
                <TableRow>
                    {headerKeys.map((key) => (
                        <TableCell key={key}>{key}</TableCell>
                    ))}
                    <TableCell>Action</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const renderTableBody = () => {
        if (data.length === 0) {
            return null; // Pas de données, donc pas de corps de tableau
        }

        return (
            <TableBody>
                {data.map((item) => (
                    <TableRow key={item.id}>
                        {Object.keys(item)
                            .filter(key => !props.keysToExclude.includes(key))
                            .map(key => (
                                <TableCell key={key}>
                                    {key === 'reservable_en_ligne' || key === 'reservable_par_heure'
                                    || key === 'reservable_par_jour' || key === 'reservable_par_mois'
                                    || key === 'reservable_par_poste' || key === 'reservable_avec_equipement'
                                        || key === 'reservable_avec_service'
                                        ? item[key] === 1 ? 'Oui' : 'Non'
                                        : item[key]}
                                </TableCell>
                            ))}
                        <TableCell>
                            <GenericMoreMenu item={props.item} itemId={item.id} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return (
        <div>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Table>
                    {renderTableHeader()}
                    {renderTableBody()}
                </Table>
            )}
        </div>
    );
}

GenericList.propTypes = {
    item: PropTypes.string.isRequired,
    keysToExclude: PropTypes.array,
};
