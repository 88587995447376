import React, { useEffect, useState } from "react";
import ThemeProvider from "./theme";
import Router from "./routes";
import $ from 'jquery'; // Assurez-vous d'avoir jQuery installé
import Cookies from 'js-cookie'; // Assurez-vous d'avoir js-cookie installé

import "./style.css";
import "./style/galleryImage.css";

import CookieConsent from "react-cookie-consent";

export default function App() {
  useEffect(() => {
    $("*").each(function () {
      const currentBackgroundColor = $(this).css("background-color");
      const currentColor = $(this).css("color");
      const currentFill = $(this).css("fill");

      if (currentBackgroundColor === "#448aff") {
        $(this).css("background-color", "#ED6A1F");
      }

      if (currentColor === "rgb(0, 122, 255)") {
        $(this).css("color", "#ED6A1F");
      }

      if (currentFill === "#007aff") {
        $(this).css("fill", "#ED6A1F");
      }
    });
  }, []);

  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (consent === 'true') {
      setCookiesAccepted(true);
      // Charger les scripts non essentiels ici
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 150 });
    setCookiesAccepted(true);
    // Charger les scripts non essentiels ici
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'false', { expires: 150 });
    setCookiesAccepted(false);
    // Supprimer les cookies non essentiels si déjà définis
  };

  return (
    <ThemeProvider>
      <CookieConsent
        location="bottom"
        buttonText="Accepter"
        declineButtonText="Refuser"
        enableDeclineButton
        cookieName="cookieConsent"
        style={{ background: "#2B373B", color: "#ffffff" }}
        buttonStyle={{ backgroundColor: "#ED6A1F", color: "#ffffff", fontSize: "14px", borderRadius: "5px", marginRight: "10px" }}
        declineButtonStyle={{ backgroundColor: "#d9534f", color: "#ffffff", fontSize: "14px", borderRadius: "5px" }}
        expires={150}
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: "0", fontSize: "14px" }}>
            Ce site utilise des cookies pour améliorer l'expérience utilisateur.{" "}
            <a href="/privacy-policy" style={{ color: "#ED6A1F", textDecoration: "underline" }}>En savoir plus</a>
          </p>
        </div>
      </CookieConsent>
      <Router />
    </ThemeProvider>
  );
}
