import "../style/cgv.css";

export default function CGV() {
    return (
        <div className={"cgv-wrapper"}>
            <h1>CONDITIONS GÉNÉRALES DE VENTE / SALLE DE RÉUNION</h1>

            <h2>Article 1 – Dispositions générales</h2>

            <p>
                Le déroulement des prestations est soumis aux dispositions des présentes conditions générales complétées
                par les conditions particulières. Les conditions générales prévalent sur toutes conditions d’achat qui
                pourraient leur être opposées.
                <br/><br/>
                Le Moule à gaufres se réserve le droit de ne pas accepter de réservation pour des réunions dont le
                caractère est incompatible avec ses valeurs ou peut porter atteinte à l’image du Moule à Gaufres.
            </p>

            <h2>Article 2 – Conditions de réservation et de règlement</h2>

            <p>
                2.1 Pour toute réservation, un devis sera établi. Le devis constitue les conditions particulières venant
                modifier ou compléter les présentes conditions générales.
                <br/><br/>
                Au cas où le Client accepte le devis, il devra le retourner dans le délai fixé aux conditions
                particulières, dûment signé et revêtu de la mention ”Bon pour accord”, ou le valider numériquement.
                <br/><br/>
                2.2 Les réservations deviennent définitives après signature par le client du devis qui vaut acceptation
                des présentes conditions générales de vente. Le solde sera facturé et devra être réglé au plus tard le
                jour de la prestation.
                <br/><br/>
                2.4 Le paiement devra s’effectuer par carte bancaire via le site internet du Moule à Gaufres ou par
                virement. Tout retard de paiement entraînera, après l’envoi d’une lettre de mise en demeure, la
                facturation d’intérêts de retard à un taux annuel égal au taux de la banque centrale européenne majoré
                de 10 points. Ces pénalités seront appliquées de la date d’exigibilité du principal à celle du paiement
                effectif et total.
                <br/><br/>
                2.5 Une indemnité forfaitaire de compensation des frais de recouvrement de 40€ sera due de plein droit
                par tous les professionnels en cas de retard de paiement (art. L441-5 du code du commerce).
            </p>

            <h2>Article 3 – Annulation du fait du client</h2>
            <p>
                Le délai de rétractation est de <b>14 jours</b>. Il court à compter de la conclusion du contrat pour les
                prestations de services.
                <br/><br/>
                En cas d’annulation totale ou partielle des prestations ou locations du Moule à Gaufres percevra auprès
                du Client à titre d’indemnité, une somme forfaitaire dont le montant dépend de la date de l’annulation
                par rapport au jour prévu pour la prestation (J).
                <br/><br/>
                Pour une annulation de la prestation :
                <br/><br/>
                - J-1 jours ouvrés avant la date prévue des prestations : 100% du montant total TTC de la prestation
                annulée.<br/><br/>
                - J-7 jours ouvrés avant la date prévue des prestations : 50% du montant total TTC de la prestation
                annulée.
                <br/><br/>
                En tout état de cause, l’acompte encaissé par le Moule à Gaufres lors de la réservation viendra en
                compensation de l’indemnité à verser par le client au titre de l’annulation.
            </p>

            <h2>Article 4 – Réglementation de la prestation</h2>
            <p>
                4.1 Le Moule à Gaufres fera tout ce qui est en son pouvoir pour assurer le déroulement de la prestation
                dans
                les conditions prévues à la réservation.
                <br/><br/>
                4.2 Le nombre de participants présents dans la salle ne pourra, en aucun cas, dépasser le nombre
                prescrit
                pour chaque salle, conformément aux indications du devis.
                <br/><br/>
                4.3 Tout matériel qui pourrait se révéler dangereux pour les clients ou le personnel sera refusé.
                <br/><br/>
                4.4 Dans le cas où le Moule à Gaufres n’assurerait pas les prestations musicales ou d’animation, les
                déclarations concernant les droits d’auteurs lors d’une manifestation avec musique et/ou attraction de
                toute
                nature doivent être faites par le client lui-même auprès de la SACEM. Il en est de même pour toutes les
                caisses de retraite, de maladie ou autres concernant les artistes ou toute déclaration à effectuer
                auprès de
                tout autre organisme. Par ailleurs, le client doit s’assurer de l’application du décret n°98-1143 du
                15/12/1998 fixant les conditions d’émission de bruits et notamment l’interdiction de dépasser 85 dB de
                niveau sonore.
                <br/><br/>
                4.6 Le Moule à Gaufres bénéficie d’une exclusivité en ce qui concerne les locations de salles et la
                restauration, sauf dérogation expresse.
                <br/><br/>
                Toute matière première fournie par le Client fera l’objet de négociations particulières concernant les
                modalités de livraison, le stockage ainsi que les compensations éventuelles. Un forfait “Mise en
                place/service équipe” sera alors appliqué. Lesdites matières premières resteront sous la responsabilité
                du
                Client et une lettre de décharge des responsabilités lui sera demandée.
                <br/><br/>
                Dans ce cadre, le Client est responsable de la qualité sanitaire des matières fournies.
                <br/><br/>
                4.7 Le Moule à Gaufres se réserve la possibilité de demander des dommages et intérêts en raison de tout
                préjudice qu’elle pourrait subir du fait de l’inobservation par le Client de l’une quelconque des
                dispositions des présentes.
            </p>
            <h2>Article 5 – Responsabilités – Assurances</h2>
            <p>
                5.1 Le Moule à Gaufres décline toute responsabilité en cas de vol ou de dégâts causés à tous types
                d’objets
                ou de biens (effets personnels, bagages à main, matériels…) appartenant au Client ou qui lui seraient
                confiés.
                <br/><br/>
                5.2 Le Moule à Gaufres facturera au client tout dégât matériel causé dans les lieux de la prestation par
                lui-même ou un membre des participants.
                <br/><br/>
                5.3 Le Moule à Gaufres déclare être assurée auprès d’une compagnie notoirement solvable pour sa
                responsabilité civile d’exploitation.
            </p>
            <h2>Article 6 – Force majeure</h2>
            <p>
                Le Moule à Gaufres se réserve la possibilité d’annuler une prestation en cas de force majeure (incendie,
                explosion, catastrophe naturelle, contraintes administratives…) ou tout autre fait indépendant de notre
                volonté. Dans ce cas, l’acompte éventuellement versé sera remboursé sans que le client puisse prétendre
                à une quelconque indemnisation supplémentaire.
            </p>
            <h2>Article 7 – Confidentialité</h2>
            <p>
                Les parties s’engagent réciproquement à garder la plus stricte confidentialité sur l’ensemble des
                documents
                et informations qui seraient portés à leur Article
            </p>

            <h2>Article 8 – Lois applicables</h2>
            <p>
                En cas de litige ou de contestation, les juridictions françaises seront seules compétentes et les lois
                françaises seules applicables. Les litiges nés avec nos clients inscrits au registre du commerce et des
                sociétés seront soumis au tribunal de commerce de Paris. Toutes les clauses ci-dessus seront
                intégralement
                respectées de part et d’autre.
            </p>
            <h2>Article 9 – Coordonnées</h2>
            <p>
                Coordonnées postales : <a href={"https://maps.app.goo.gl/Pf1Z1c4izs1kRs5j8"} target={"_blank"}>4 rue
                André Dessaux 45400
                Fleury Les Aubrais</a>
                <br/><br/>
                Téléphone : <a href={"tel:0666235752"}>06 66 23 57 52</a>
                <br/><br/>
                Adresse électronique : <a href={"mailto:benjamin@mouleagaufres.com"}>benjamin@mouleagaufres.com</a>
            </p>
        </div>
    );
}