import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Box, AppBar, Toolbar, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../components/Logo";
import NavSection from "../../components/NavSection";
import NavConfig from "../navConfig/NavConfig";
import "../../style/header.css";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100%)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const MobileMenuIcon = styled(IconButton)(({ theme }) => ({
  display: "block", // Afficher par défaut
  [theme.breakpoints.up("lg")]: {
    display: "none", // Masquer sur les écrans larges
  },
}));

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.primary.contrastText,
}));

export default function DashboardNavbar({ onOpenSidebar }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <RootStyle className="navbar">
      <ToolbarStyle className="navbar-wrapper">
        <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }} className="navbar-logo">
          <Logo sx={{ width: "15%" }} />
        </Box>
        <MobileMenuIcon edge="start" color="inherit" onClick={toggleDrawer} className="navbar-burger">
          <MenuIcon />
        </MobileMenuIcon>
        <DrawerStyle
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          variant="temporary"
          className="sidebar-menu"
        >
          <CloseButton onClick={toggleDrawer}>
            <CloseIcon />
          </CloseButton>
          <NavSection navConfig={NavConfig} />
        </DrawerStyle>
      </ToolbarStyle>
      {/* Ajoutez ici le contenu du menu pour le mode desktop */}
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <NavSection navConfig={NavConfig} />
      </Box>
    </RootStyle>
  );
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};