import Carousel from "react-material-ui-carousel";
import PropTypes from "prop-types";

export default function DCarousel(props) {
    return (
        <div className={"carousel-wrapper"}>
            {
                props.urls.length > 0 &&
                <Carousel
                    animation={"slide"}
                    className={"carousel"}
                    indicators={true}
                    navButtonsAlwaysInvisible={true}
                    height={"45vw"}
                    autoPlay={true}
                    indicatorContainerProps={{style: {position: "relative", zIndex: 10, marginBottom: ".5rem"}}}
                    indicatorIconButtonProps={{style: { color: "#E8E8E8", margin: "0 2px"}}}
                    activeIndicatorIconButtonProps={{style: { color: "#ED6A1F"}}}
                >
                    {props.urls.map((url, index) => (
                    <img src={url} alt={"img"} key={index}/>
                    ))}

                </Carousel>
            }
            {/*
                props.items.length > 0 &&
                <Carousel
                    animation={"slide"}
                    indicators={true}
                    navButtonsAlwaysVisible={false}
                >
                    {
                        props.items.map((item, index) => (
                            <div key={index} className={"carousel-item"}>
                                <img src={item.image} alt={item.title} className={"carousel-image"}/>
                                <div className={"carousel-text"}>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            */}
        </div>
    );
}

DCarousel.propTypes = {
    urls: PropTypes.array.isRequired,
};
