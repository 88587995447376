import {Form, FormikProvider, useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress, FormControlLabel, Stack, Switch, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {useEffect, useState} from "react";
import {useMyContext} from "../../../MyContext";
import PropTypes from "prop-types";

export default function GenericAddForm(props) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const {token} = useMyContext();
    const {reinitData, setReinitData} = useMyContext();

    const typesTextfield = ["bigint", "int","float", "password", "email", "string", "date", "url"];
    const fieldsToExclude = ["id", "created_at", "updated_at"];

    const [attributs, setAttributs] = useState({});
    const [loading, setLoading] = useState(true); // État pour gérer le chargement

    useEffect(() => {
        const requete = axios.create({
            baseURL: `${apiUrl}/attributs/${props.item}`,
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        requete().then((response) => {
            setAttributs(response.data);
            setLoading(false);
        }, (error) => {
            console.error(error);
            navigate("/404");
        });
    }, []);



    const generateInitialValues = () => {
        const valeursInitiales = {};

        Object.entries(attributs).forEach(
            ([attribut, typeAttribut]) => {
                if (!fieldsToExclude.includes(attribut)) {
                    typeAttribut === "bigint" || typeAttribut === "int" || typeAttribut === "float" ?
                        valeursInitiales[attribut] = 0 :
                        typeAttribut === "boolean" ? valeursInitiales[attribut] = false : valeursInitiales[attribut] = "";
                }
            }
        );
        return valeursInitiales;
    };

    const formik = useFormik({
        initialValues: generateInitialValues(),
        enableReinitialize: true, // Permet de réinitialiser les valeurs initiales après le chargement
        onSubmit: async (values) => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.post(
                    `${apiUrl}/${props.item}`, // Ne surtout pas laisser un '/' à la fin sous peine d'erreur CORS
                    values,
                    {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                );
                setReinitData(!reinitData);
                alert("Votre requête a bien été exécutée.");

            } catch (error) {
                console.error(error);
                alert("Une erreur est survenue lors de l'ajout de l'élément");
            }
        },
    });

    const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps} = formik;

    const getFieldType = (attribut) => {
        switch (attribut.type) {
            case "int":
                return "number";
            case "bigint":
                return "number";
            case "float":
                return "number";
            case "string":
                return "text";
            case "date":
                return "date";
            case "url":
                return "url";
            case "email":
                return "email";
            case "password":
                return "password";
            default:
                return "text";
        }
    };

    return (
        <div>
            {loading ? (
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 4}}>
                    <CircularProgress/>
                </Box>
            ) : (
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <br/>
                        <Stack spacing={3}>
                            {
                                Object.entries(attributs).map(([attribut, typeAttribut]) => {
                                    if (fieldsToExclude.includes(attribut)) {
                                        return null;
                                    }
                                    if (typesTextfield.includes(typeAttribut)) {
                                        return (
                                            <TextField
                                                key={attribut}
                                                fullWidth
                                                type={getFieldType(typeAttribut)}
                                                label={attribut}
                                                {...getFieldProps(attribut)}
                                                error={Boolean(touched[attribut] && errors[attribut])}
                                                helperText={touched[attribut] && errors[attribut]}
                                            />
                                        );
                                    } else if (typeAttribut === "boolean") {
                                        return (
                                            <FormControlLabel
                                                key={attribut}
                                                control={
                                                    <Switch
                                                        checked={values[attribut]}
                                                        onChange={(event) => {
                                                            formik.setFieldValue(attribut, event.target.checked);
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                                label={attribut}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }

                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                id="register_button"
                            >
                                Ajouter
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            )}
        </div>
    );
}

GenericAddForm.propTypes = {
    item: PropTypes.string.isRequired,
};
