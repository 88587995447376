import axios from "axios";
import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Button, Typography } from "@mui/material";
import EquipementMoreMenu from "./EquipementMoreMenu";
import AddEquipementForm from "./AddEquipementForm";

export default function ListeEquipements() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const equipements = axios.create({
        baseURL: `${apiUrl}/equipements`,
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // État pour gérer le chargement
    const [showForm, setShowForm] = useState(false); // État pour basculer entre la table et le formulaire

    useEffect(() => {
        equipements.get().then((response) => {
            setData(response.data);
            setLoading(false); // Fin du chargement lorsque les données sont récupérées
        }).catch(() => {
            setLoading(false); // Fin du chargement même en cas d'erreur
        });
    }, []);

    const handleToggle = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">Gestion des Équipements</Typography>
                <Button variant="contained" color="primary" onClick={handleToggle}>
                    {showForm ? "Voir la liste des équipements" : "Ajouter un équipement"}
                </Button>
            </Box>

            {showForm ? (
                <AddEquipementForm />
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prix</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Link to={`/salle/${item.id}`}>{item.nom}</Link>
                                </TableCell>
                                <TableCell>{item.prix}</TableCell>
                                <TableCell>
                                    <EquipementMoreMenu equipementId={item.id} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
}
