import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, IconButton, InputAdornment, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/password/reset`, {
        token,
        email,
        password,
        password_confirmation: passwordConfirmation,
      });
      setMessage(response.data.message);
      setError({});
      navigate('/'); // Redirection vers la page d'accueil
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setError(error.response.data.errors);
      } else {
        setError({ general: 'Une erreur est survenue. Veuillez réessayer.' });
      }
      setMessage('');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Réinitialiser le mot de passe
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          error={!!error.email}
          helperText={error.email && (Array.isArray(error.email) ? error.email.join(', ') : error.email)}
        />
        <TextField
          label="Nouveau mot de passe"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!error.password}
          helperText={error.password && (Array.isArray(error.password) ? error.password.join(', ') : error.password)}
        />
        <TextField
          label="Confirmer le nouveau mot de passe"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin="normal"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!error.password_confirmation}
          helperText={error.password_confirmation && (Array.isArray(error.password_confirmation) ? error.password_confirmation.join(', ') : error.password_confirmation)}
          style={{ marginBottom: '24px' }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ textTransform: 'none', backgroundColor: '#FF5733' }}
        >
          Réinitialiser le mot de passe
        </Button>
      </form>
      {message && <Typography color="primary" style={{ marginTop: '16px' }}>{message}</Typography>}
      {error.general && (
        <Typography color="error" style={{ marginTop: '16px' }}>{error.general}</Typography>
      )}
    </Container>
  );
};

export default ResetPassword;
