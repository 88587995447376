import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { MyContext } from "../MyContext";
import "../style/IncrementDecrementComponent.css";

const IncrementDecrementComponent = () => {
  const { setNbPostes, nb_postes } = useContext(MyContext);

  const handleIncrement = () => {
    setNbPostes(nb_postes + 1);
  };

  const handleDecrement = () => {
      nb_postes > 1 ? setNbPostes(nb_postes - 1) : setNbPostes(1);
  };

  const buttonStyle = {
    minWidth: "40px", // Adjust this value as needed
    minHeight: "40px", // Adjust this value as needed
    padding: "0", // Remove default padding
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p className="pour-responsive compteur-label">J'ai besoin de </p>
      <Button style={buttonStyle} onClick={handleDecrement} className={"retrait"}>
        -
      </Button>
      <Typography variant="p" className={"nb-postes"}>
        {nb_postes}
      </Typography>
      <Button style={buttonStyle} onClick={handleIncrement} className={"ajout"}>
        +
      </Button>
      <p>bureau(x)</p>
    </div>
  );
};

export default IncrementDecrementComponent;
