import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography} from '@mui/material';
import {useMyContext} from "../MyContext";

const ServiceSelector = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { selectedServices, setSelectedServices, setTotalPrixServices } = useMyContext();

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/services`);
                setServices(response.data);
            } catch (error) {
                console.error(error);
                setError('Erreur lors de la récupération des services');
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    useEffect(() => {
        calculateTotalPrixServices();
    }, [selectedServices]);

    const handleServiceChange = (event, service) => {
        const { checked } = event.target;
        const newSelectedServices = checked
            ? [...selectedServices, { service, people: 1 }]
            : selectedServices.filter(s => s.service.id !== service.id);

        setSelectedServices(newSelectedServices);
    };

    const handlePeopleChange = (event, service) => {
        let { value } = event.target;
        value = Math.max(1, Math.floor(Number(value))); // Assurer une valeur entière >= 1
        const newSelectedServices = selectedServices.map(s =>
            s.service.id === service.id ? { ...s, people: value } : s
        );

        setSelectedServices(newSelectedServices);
    };

    const calculateTotal = (service) => {
        const selectedService = selectedServices.find(s => s.service.id === service.id);
        return selectedService ? selectedService.people * service.prix_unitaire : 0;
    };

    const calculateTotalPrixServices = () => {
        const total = selectedServices.reduce((sum, s) => sum + s.people * s.service.prix_unitaire, 0);
        setTotalPrixServices(total);
    };

    if (loading) return <Typography>Chargement des services...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <div>
            <FormGroup>
                {services.map(service => (
                    <Grid container spacing={2} alignItems="center" key={service.id}>
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedServices.some(s => s.service.id === service.id)}
                                        onChange={(e) => handleServiceChange(e, service)}
                                    />
                                }
                                label={service.nom}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography variant="body1">{service.prix_unitaire} € / personne</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                type="number"
                                label="Nombre de personnes"
                                variant="standard"
                                size="small"
                                value={selectedServices.find(s => s.service.id === service.id)?.people || ''}
                                onChange={(e) => handlePeopleChange(e, service)}
                                disabled={!selectedServices.some(s => s.service.id === service.id)}
                                inputProps={{ min: 1, step: 1 }}  // Assurer l'incrément entier
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="h6">
                                Total: {calculateTotal(service)} €
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </FormGroup>
        </div>
    );
};

export default ServiceSelector;
