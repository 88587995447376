import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import RegisterForm from '../components/forms/register/RegisterForm';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>

        </HeaderStyle>

        <Container maxWidth='sm'>
          <div class='title-login'>

            <Typography variant="h5" gutterBottom className='bienvenue'>
              BIENVENUE AU MOULE À GAUFRES
            </Typography>
            <Typography variant="h3" gutterBottom className='creer-compte'>
              Créer un compte
            </Typography>
          </div>

            <RegisterForm redirectToHome={true}/>

              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }} className="connexion-proposition">
                Déjà un compte ? {' '}
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                   Connexion
                </Link>
              </Typography>

        </Container>
      </RootStyle>
    </Page>
  );
}
