import React, { useState, useEffect } from "react";
import { Button, Container, Typography, Box, Collapse } from "@mui/material";
import GenericList from "../components/GenericList";
import GenericAddForm from "../components/forms/add/GenericAddForm";
import { useMyContext } from "../MyContext";

// ----------------------------------------------------------------------

export default function ManageAvantages() {
    // Vérifier si l'utilisateur est admin
    const { redirectIfNotAdmin } = useMyContext();
    useEffect(() => {
        redirectIfNotAdmin();
    }, [redirectIfNotAdmin]);

    const keysToExclude = ["id", "created_at", "updated_at"];
    const [showForm, setShowForm] = useState(false); // État pour basculer entre la liste et le formulaire

    const handleToggle = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">
                    {showForm ? "Ajouter un nouvel avantage" : "Avantages des locations"}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggle}
                >
                    {showForm ? "Voir la liste des avantages" : "Ajouter un nouvel avantage"}
                </Button>
            </Box>

            <Collapse in={!showForm}>
                <GenericList item={"avantages"} keysToExclude={keysToExclude} />
            </Collapse>

            <Collapse in={showForm}>
                <GenericAddForm item={"avantages"} />
            </Collapse>
        </Container>
    );
}
