import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function Logo() {

  return (
    <RouterLink to="https://atelier.mouleagaufres.com/">
      <img
        src="https://atelier.mouleagaufres.com/wp-content/uploads/2022/03/logoMAG-blanc.png"
        alt="logoMAG"
      />
    </RouterLink>
  );
}
