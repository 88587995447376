import React, { useState } from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import GenericList from "../components/GenericList";
import GenericAddForm from "../components/forms/add/GenericAddForm";

export default function ManageTypesEspaces() {
    const keysToExclude = ["id", "created_at", "updated_at"];
    const [showForm, setShowForm] = useState(false);

    const handleToggle = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4">
                    Types de salles
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggle}
                >
                    {showForm ? "Voir la liste" : "Ajouter un type de salle"}
                </Button>
            </Box>

            {showForm ? (
                <>
                    <Typography variant="h5" gutterBottom>
                        Ajouter un nouveau type de salle
                    </Typography>
                    <GenericAddForm item={"typesespaces"} />
                </>
            ) : (
                <GenericList item={"typesespaces"} keysToExclude={keysToExclude} />
            )}
        </Container>
    );
}
