import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {useMyContext} from "../../../MyContext";

export default function GenericModifyForm() {
    const {token} = useMyContext();

    // Récupérer les infos des paramètres de l'URL
    const {itemsName, itemId} = useParams();

    const [item, setItem] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        try {
            const response = axios.get(
                `${apiUrl}/user`,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );
            response.then((res) => {
                setItem(res.data);
                setDataLoaded(true); // Indiquer que les données sont chargées
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, [itemId, token]);

    const handleSubmit = async (values) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir modifier cet élément ?");
        if (!confirmation) {
            return;
        }

        try {
            const updateResponse = await axios.put(
                `${apiUrl}/update-user`,
                values,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );

            // recharge la page
            window.location.reload();
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    if (!dataLoaded) {
        return <div>Loading...</div>; // Ou affichez un spinner ou un autre indicateur de chargement
    }

    const initialValues = {
        nom: item?.nom || '',
        prenom: item?.prenom || '',
        entreprise: item?.entreprise || '',
        siret: item?.siret || '',
        adresse: item?.adresse || '',
        code_postal: item?.code_postal || '',
        ville: item?.ville || '',
        email: item?.email || '',
        mot_de_passe: '',
        confirmation_mot_de_passe: ''
    };

   // Fonction utilitaire pour formater les labels
   const formatLabel = (label) => {
    return label.replace(/_/g, ' ').charAt(0).toUpperCase() + label.replace(/_/g, ' ').slice(1);
};

    return (
        <div className="container">
            <h2>Modifier</h2>

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({isSubmitting}) => (
                    <Form>
                        {Object.keys(initialValues).map(key => (
                            <div key={key} className="mb-3">
                                <label htmlFor={key} className="form-label">
                                    {formatLabel(key)}
                                </label>
                                <div className="input-group">
                                    <Field
                                        type={(key.includes('mot_de_passe') && !showPassword) ? 'password' : 'text'}
                                        id={key}
                                        name={key}
                                        className="form-control"
                                    />
                                    {key.includes('mot_de_passe') && (
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="btn btn-secondary"
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}

                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            ) : (
                                "Modifier"
                            )}
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    );
}
