import React from "react";
import "../../style/footer.css";

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-left footer-item">
                    <h1 className="title">Moule à Gaufres</h1>
                </div>

                <div className="footer-center footer-item">
                    <div className="menu">
                        <h3>Menu</h3>
                        <ul>
                            <li><a href='/'>Les salles</a></li>
                            <li><a href='https://mouleagaufres.com/#contact'>Contact</a></li>
                        </ul>
                    </div>
                    <div className="menu">
                        <h3>Services</h3>
                        <ul>
                            <li><a href='https://reservation.mouleagaufres.com/evenements'>Les évènements</a></li>
                            <li><a href='https://boutique.mouleagaufres.com/'>La boutique</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-empty footer-item"></div>
                <div className="footer-right footer-item">
                    <a href="https://www.facebook.com/agencedesmonstres">
                        <img
                            src="https://mouleagaufres.com/wp-content/uploads/2023/07/facebook.svg"
                            alt="Facebook"
                        />
                    </a>
                    <a href="https://www.instagram.com/agence_des_monstres/">
                        <img
                            src="https://mouleagaufres.com/wp-content/uploads/2023/07/instagram.svg"
                            alt="Instagram"
                        />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <p className="footer-text copyright">
                    Copyright &copy; 2023 Moule à Gaufres / Fièrement réalisé par l'agence
                    des Monstres
                </p>
                <p className="footer-text">
                    <a href="https://mouleagaufres.com/mentions-legales/">
                        Mentions légales
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
