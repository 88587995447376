import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMyContext } from "../../MyContext";
import "../../style/factures.css";
import Button from "@mui/material/Button";

export default function Factures() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { token } = useMyContext();
  const [loading, setLoading] = useState(true);

  const invoices = axios.create({
    baseURL: `${apiUrl}/evoliz/invoices`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    invoices.get().then((response) => {
      setData(response.data.invoices.data);
    });
  }, []);

  return (
    <div>
      <div className="historique-factures">
        <h2 className="historique-factures-title account-title">
          Historique de factures
        </h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "/coworkingbilling")}
        >
          Demander une facture pour le coworking
        </Button>
      </div>

      <ul>
        {data.map((invoice, index) => {
          // Extraire le nom de la salle de la chaîne "designation" et supprimer "Nom :"
          const designationParts = invoice.items[0].designation.split("|");
          const namePart = designationParts[0].replace("Nom :", "").trim();

          return (
            <div key={index}>
              <div className="facture">
                <div className="infos-facture">
                  <b>Salle {namePart}</b>
                  <p>
                    Facture du{" "}
                    {new Date(invoice.status_dates.create).toLocaleDateString()}
                  </p>
                </div>
                <p className="lien-facture">
                  <a
                    href={invoice.webdoc}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Voir la facture
                  </a>
                </p>
              </div>
              <div className="horizontal_separator horizontal_separator_factures"></div>
            </div>
          );
        })}
      </ul>
    </div>
  );
}
