import React from "react";

const GoogleMap = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.70243824914!2d1.8997657156415066!3d47.92279477920677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e4fb20fb5b298b%3A0xd5f36a4fa9b091a4!2sRue%20Andr%C3%A9%20Dessaux%2C%2045400%20Fleury-les-Aubrais!5e0!3m2!1sfr!2sfr!4v1690814297663!5m2!1sfr!2sfr"
      width="100%"
      height="450"
      style={{ border: 0, borderRadius: "8px"}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
    ></iframe>
  );
};

export default GoogleMap;

