import React, {useState} from "react";
import "../../style/navConfig.css";
import {useMyContext} from "../../MyContext";

const NavConfig = () => {
    const { token, userRole } = useMyContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isAdmin = userRole === "admin";
    const isAuthenticated = token !== null && token !== undefined && token !== "";

    const navConfig = [
        ...(isAdmin
            ? [
                { title: "Réserver", path: process.env.REACT_APP_URL },
                { title: "Utilisateurs", path: "/users" },
                //{ title: "Roles", path: "/manageroles" },
                { title: "Factures", path: "https://www.evoliz.com/mag/invoice/" },
                { title: "Salles", path: "/manageespaces" },
                { title: "Types de salles", path: "/managetypesespaces" },
                { title: "Équipements", path: "/manageequipements" },
                { title: "Demandes de devis", path: "/managedemandes" },
                { title: "Avantages", path: "/manageavantages" },
                { title: "Services", path: "/manageservices" },
                { title: "Photos carrousel", path: "/managephotos" },
            ]
            : [
                { title: "Les évènements", path: "/evenements" },
                { title: "Les laboratoires", path: "https://atelier.mouleagaufres.com/leslaboratoires" },
                { title: "Réserver", path: process.env.REACT_APP_URL },
                { title: "Contact", path: "https://atelier.mouleagaufres.com/#contact" },
            ]),
        ...(isAuthenticated
            ? [{ title: "Compte", path: "/account/informations" }]
            : [{ title: "Connexion", path: "/login" }]),
    ];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="nav-links">
            {isAdmin ? (
                <div className="admin-menu">
                    <button onClick={toggleMenu} className="admin-menu-toggle">
                        Menu Admin
                    </button>
                    {isMenuOpen && (
                        <div className="dropdown-menu">
                            {navConfig.map((item, index) => (
                                <a key={index} href={item.path} className="nav-link admin-link">
                                    {item.title}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                navConfig.map((item, index) => (
                    <a key={index} href={item.path} className="nav-link">
                        {item.title}
                    </a>
                ))
            )}
        </div>
    );
};

export default NavConfig;
